export const LANGUAGE_DUTCH = {
  delete: "Verwijder",

  dailyRevenue: "Dagontvangsten",
  fillIn: "Invullen",
  history: "Historiek",
  profile: "Profiel",
  description: "Omschrijving",
  payment: "Betalingswijze",
  total: "Totaal",
  dayTotal: "Dagtotaal",
  saveWholeDay: "Bewaren",
  addRow: "Nieuwe rij",
  saveCloseDay: "Bewaren en sluiten",
  date: "Datum",
  month: "Maand",
  logout: "Uitloggen",
  companyDetails: "Bedrijfsgegevens",
  lockLineTooltip:
    "Deze rij afsluiten. Je kan hierna deze rij niet meer aanpassen",
  lockFulldayTooltip:
    "Deze volledige dag afsluiten. Dit sluit alle onderliggende rijen van deze dag af, je kan deze niet meer aanpassen.",
  save: "Opslaan",

  name: "Naam",
  vat: "BTW Nr.",
  street: "Straat",
  number: "Huisnummer",
  zipCode: "Postcode",
  city: "Stad",
  addCompany: "Bedrijf toevoegen",
  submit: "Verzenden",
  nameRequired: "Naam is vereist",
  vatRequired: "BTW Nr. is vereist",
  streetRequired: "Straat is vereist",
  numberRequired: "Huisnummer is vereist",
  zipCodeRequired: "Postcode is vereist",
  cityRequired: "Stad is vereist",
  dateRequired: "Datumbedrag is vereist",
  totalRequired: "Totaalbedrag is vereist",
  amountRequired: "Bedrag is vereist en mag niet 0 zijn",
  descriptionRequired: "Omschrijving bedrag is vereist",
  relationRequired: "Relatie is vereist",

  dashboard: "Dashboard",
  openingStatement: "Begin met het toevoegen van uw bedrijf.",
  paymentMethod: "Betalingswijze",
  status: "Toestand",
  startDate: "Startdatum",
  endDate: "Einddatum",
  cashRegister: "Kassa",
  amount: "Bedrag",

  confirmationMessage: "Weet u zeker dat u wilt sluiten?",
  revenueAdded: "Opbrengst is succesvol toegevoegd",
  revenueClosed: "De omzet is succesvol afgesloten",
  revenueUpdate: "De omzet is succesvol bijgewerkt",
  companyAdded: "Bedrijf is succesvol toegevoegd",
  cashAdded: "Geld is succesvol toegevoegd aan de kassa",
  cashUpdated: "De kassa is succesvol bijgewerkt",
  relation: "Relatie",
  openingBalance: "Beginsaldo",
  closingBalance: "Eindsaldo",

  coda: "Coda tools",
  "csv-to-coda-accept":
    "Even geduld, het bestand wordt omgezet en automatisch gedownload!",
  "csv-to-coda-ready":
    "Drag & drop hier uw CSV bestand dat u wilt converteren (of klik om een file browser te openen).",
  "csv-to-coda-reject": "U heb geen geldig CSV bestand geselecteerd.",

  createdUsers: "Mijn gebruikers",
  administrators: "Accountants",
  linkedUsers: "Gelinkte gebruikers",
  accountingFirms: "Accountants",
  linkedAdmins: "Gekoppelde accountants",
  inLinkRequests: "Linkverzoeken",
  outLinkRequests: "Linkverzoeken",
  companies: "Bedrijven",
  control: "Beheer",

  accountingFirmsHeading: "Accountantskantoren",
  email: "Email",
  linkStatus: "Toestand",
  unlinked: "Onverbonden",
  linkRequestSent: "Linkverzoek verzonden",
  sendLinkRequest: "Linkverzoek verzenden",
  linked: "Gelinkt",

  administratorsHeading: "Accountantskantoren",

  manageCompany: "Beheren",
  manageNoActions: "Geen acties beschikbaar",
  manageHeading: "Beheren",

  addUserToCompany: "Gebruiker toevoegen aan bedrijf",
  emailVerified: "Email geverifieerd",
  actions: "Acties",
  yes: "Ja",
  no: "Nee",
  deleteUserFromCompanyConfirmation:
    "Weet u zeker dat u de gebruiker uit een bedrijf wilt verwijderen?",

  incomingLinkRequestsHeading: "Inkomende linkverzoeken",
  cancelLinkRequestsConfirmation:
    "Weet je zeker dat je het linkverzoek wilt annuleren?",
  acceptLinkRequestConfirmation:
    "Weet je zeker dat je het linkverzoek wilt accepteren?",
  acceptBtn: "Aanvaarden",
  cancelBtn: "Annuleren",
  linkedAdminsHeading: "Gekoppelde accountants",
  unlinkBtn: "Ontkoppelen",
  unlinkConfirmation: "Weet je zeker dat je wilt ontkoppelen?",
  linkedUsersHeading: "Gelinkte gebruikers",
  outcomingLinkRequestsHeading: "Uitkomende linkverzoeken",
  cancelLinkRequestBtn: "Annuleren",
  cancelLinkConfirmation:
    "Weet je zeker dat je het linkverzoek wilt annuleren?",

  manageUsersHeading: "Gebruikers beheren",
  createUserHeading: "Gebruiker toevoegen",
  deleteUserConfirmation: "Weet u zeker dat u de gebruiker wilt verwijderen?",
  searchUserLabel: "Zoek gebruiker",
  searchUserPlaceholder: "Typ gebruikersnaam",
  firstName: "Voornaam",
  lastName: "Achternaam",
  password: "Wachtwoord",

  emailRequired: "Email is vereist",
  notValidEmail: "Email is niet geldig",
  firstNameRequired: "Voornaam is verplicht",
  lastNameRequired: "Achternaam is verplicht",
  passwordRequired: "Een wachtwoord is verplicht",
  passwordFormat:
    "Wachtwoord moet minimaal 8 tekens lang zijn. Ten minste één kleine letter (a-z), hoofdletter (A-Z), cijfer (0-9) en speciaal teken (!@#$%^&*)",
  public: "Publiek",
  noCompaniesAssigned: "Uw accountant heeft u nog geen bedrijf toegewezen",

  export: "Exporteer",
  exportDailyRevenue: "Exporteer dagontvangsten (.csv)",
  exportDailyRevenueDetail: "Exporteer dagontvangsten BTW detail (.csv)",
};
