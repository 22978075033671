import React, { useContext, useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import SendIcon from "@mui/icons-material/Send";
import "../../locale/i18n";
import { ProfileContext } from "../../providers/profile";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { updateUserLanguage } = useContext(ProfileContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (languageCode: string) => {
    updateUserLanguage(languageCode)
      .then(() => i18n.changeLanguage(languageCode))
      .finally(() => setAnchorEl(null));
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-controls="language-menu"
        aria-haspopup="true"
        aria-label=""
        color="inherit"
        onClick={handleClick}
        size="large"
      >
        <LanguageIcon />
      </IconButton>

      <Menu
        id="language-menu"
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => changeLanguage("nl")}
          selected={i18n.language === "nl" && true}
        >
          <ListItemIcon style={{ minWidth: "25px" }}>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Dutch" />
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage("en")}
          selected={i18n.language === "en" && true}
        >
          <ListItemIcon style={{ minWidth: "25px" }}>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="English" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageSelector;
