import { Box, Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CodaUtils.module.scss";
import { useDropzone } from "react-dropzone";
import { useCodaUtilsAPI } from "../../providers/codaUtils";
import { UploadFile } from "@mui/icons-material";

export default function CashRegisterPage() {
  const { t: translate } = useTranslation();
  const { convertCsvToCoda } = useCodaUtilsAPI();

  const onDropAccepted = async (files: Array<File>) => {
    console.log(`Accepted`);
    for (const file of files) {
      await convertCsvToCoda(0, file);
    }
    // files.forEach(file => await convertCsvToCoda(0, file))
  };
  function Accept() {
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: {
        "text/csv": [".csv"],
        "application/vnd.ms-excel": [".csv"],
      },
      onDropAccepted,
    });

    return (
      <Paper
        {...getRootProps({
          className: `${styles.dropzone} ${
            isDragActive ? styles.dropActive : ""
          }`,
        })}
        elevation={isDragActive ? 5 : 0}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1.5em",
            marginTop: "1.5em",
          }}
        >
          {<UploadFile sx={{ color: "#307fec", fontSize: "2.5em" }} />}
          {isDragAccept && <p>{translate("csv-to-coda-accept")}</p>}
          {isDragReject && <p>{translate("csv-to-coda-reject")}</p>}
          {!isDragActive && <p>{translate("csv-to-coda-ready")}</p>}
        </Box>
      </Paper>
    );
  }

  return (
    <>
      <div className={styles.heading}>
        <h2>{translate("coda")} </h2>
      </div>
      <div className={styles.codaUtils}>
        <Accept />
      </div>
    </>
  );
}
