import { format, startOfMonth, endOfMonth, eachDayOfInterval } from "date-fns";

export const staticTime = "T23:59:59.999Z";

export const dateFormat = (date: Date) => {
  return format(date, "dd/MM/yyyy");
};

export const yearDateFormat = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const getDatesOfMonthTillToday = (
  selectedDate: Date,
  customRange?: {
    begin: Date;
    end: Date;
  }
) => {
  const now = new Date();
  const start = customRange?.begin || startOfMonth(selectedDate);
  const end =
    customRange?.end ||
    (selectedDate.getMonth() === now.getMonth()
      ? now
      : endOfMonth(selectedDate));

  return eachDayOfInterval({ start, end });
};

export const filterAndFormatDate = (day: string) => {
  const getDate = day.includes("T") ? day.split("T")[0] : day;
  return dateFormat(new Date(getDate));
};
