import React, { useEffect, useState } from "react";
import { Users } from "../../api/types";
import { useUsers } from "../../providers/users";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

interface UserSearchProps {
  onChange: (user: Users.Dto.UserDto) => void;
}

export default function UserSearch(props: UserSearchProps) {
  const { createdUsers, getCreatedUsers } = useUsers();
  const [options, setOptions] = useState<Users.Dto.UserDto[]>([]);
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation();

  useEffect(() => {
    loadUsers("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (createdUsers && createdUsers.items && createdUsers.items.length) {
      console.log({ createdUsers });
      setOptions(createdUsers.items);
    }
  }, [createdUsers]);

  const changeOptionBaseOnValue = (value: string) => {
    loadUsers(value);
  };

  const loadUsers = (searchTerm: string) => {
    setLoading(true);
    getCreatedUsers({ query: searchTerm, pageNumber: 1, pageSize: 50 }).finally(
      () => setLoading(false)
    );
  };

  const handleChange = (event: any, value: any) => {
    props.onChange(value);
  };

  const handleInputChange = (event: object, value: string, reason: string) => {
    if (reason === "input") {
      changeOptionBaseOnValue(value);
    }
  };

  return (
    <Autocomplete
      id="user-search"
      style={{ width: 300 }}
      getOptionLabel={(option) => `${option.name} (${option.email})`}
      options={options}
      loading={loading}
      placeholder={translate("searchUserPlaceholder")}
      onChange={handleChange}
      onInputChange={debounce(
        (event: object, value: string, reason: string) =>
          handleInputChange(event, value, reason),
        500
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate("searchUserLabel")}
          placeholder={translate("searchUserPlaceholder")}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
