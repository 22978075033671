import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Selections from "../Selections/Selections";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LanguageSelector from "../ChangeLanguage/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { MenuRounded } from "@mui/icons-material";
import { KmodoLogoSmall } from "../Logos";

type IHeaderBar = {
  handleDrawerToggle: () => void;
  open: boolean;
};

const HeaderBar = ({ handleDrawerToggle, open }: IHeaderBar) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t: translate } = useTranslation();
  const { logout } = useAuth0();
  const isMobileOrTablet = useMediaQuery("(max-width:600px)");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.headerWrapper}>
      <AppBar
        position="fixed"
        className={clsx(styles.appBar, {
          [styles.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div className={styles.header}>
            <div className={styles.menuRow}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                className={styles.menuButton}
                size="large"
              >
                {isMobileOrTablet ? (
                  <MenuRounded />
                ) : open ? (
                  <ArrowBackIosIcon />
                ) : (
                  <ArrowForwardIosIcon />
                )}
              </IconButton>
              {isMobileOrTablet && <KmodoLogoSmall />}
            </div>

            <Selections />

            <div className={styles.headerActions}>
              <LanguageSelector />
              <IconButton
                edge="end"
                aria-controls="header-menu"
                aria-haspopup="true"
                aria-label=""
                color="inherit"
                onClick={handleClick}
                size="large"
              >
                <AccountCircle />
              </IconButton>

              <Menu
                id="header-menu"
                anchorEl={anchorEl}
                elevation={0}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate("/company")}>
                  {translate("companyDetails")}{" "}
                </MenuItem>
                <MenuItem
                  onClick={() => logout({ returnTo: window.location.origin })}
                >
                  {translate("logout")}
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderBar;
