import * as Yup from "yup";
import i18n from "../../locale/i18n";

export type CompanyAddFormValues = {
  name: string;
  vAT: string;
  street: string;
  number: string;
  zipcode: number | "";
  city: string;
};

export const validationSchema = () =>
  Yup.object({
    name: Yup.string().required(i18n.t("nameRequired")),
    vAT: Yup.string().required(i18n.t("vatRequired")),
    street: Yup.string().required(i18n.t("streetRequired")),
    number: Yup.string().required(i18n.t("numberRequired")),
    zipcode: Yup.number()
      .required(i18n.t("zipCodeRequired"))
      .moreThan(999)
      .lessThan(10000)
      .integer(),
    city: Yup.string().required(i18n.t("cityRequired")),
  });
const toValuesNumber = (value?: number | null) =>
  typeof value === "number" ? value : "";

export const initialValues: CompanyAddFormValues = {
  name: "",
  vAT: "",
  street: "",
  number: "",
  zipcode: toValuesNumber(null),
  city: "",
};
