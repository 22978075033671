import { Configuration } from './Configuration';

const configFile = process.env.REACT_APP_CONFIG_JSON || 'config.json';
const configJsonUrl = `${process.env.PUBLIC_URL}/${configFile}`;

export async function loadConfig() {
    const configResponse = await fetch(configJsonUrl);
    const config = (await configResponse.json()) as Configuration;

    return config;
}