export const LANGUAGE_ENGLISH = {
  delete: "Delete",

  dailyRevenue: "Daily Revenue",
  fillIn: "Fill in",
  history: "History",
  profile: "Profile",
  description: "Description",
  payment: "Payment",
  total: "Total",
  dayTotal: "Day Total",
  saveWholeDay: "Save",
  addRow: "Add row",
  saveCloseDay: "Save & close day",
  date: "Date",
  month: "Month",
  logout: "Logout",
  companyDetails: "Company Details",
  lockLineTooltip:
    "Close this row. After this, you can't edit this row anymore.",
  lockFulldayTooltip:
    "Close this whole day. This closes all underlying rows of this day, you can't edit this anymore.",
  save: "Save",

  name: "Name",
  vat: "VAT",
  street: "Street",
  number: "Number",
  zipCode: "Zip Code",
  city: "City",
  addCompany: "Add Company",
  submit: "Submit",

  nameRequired: "Name is required",
  vatRequired: "VAT is required",
  streetRequired: "Street is required",
  numberRequired: "Number is required",
  zipCodeRequired: "Zip code is required",
  cityRequired: "City is required",
  totalRequired: "Total amount is required",
  amountRequired: "Amount is required and can't be 0",
  dateRequired: "Date is required",
  descriptionRequired: "Description is required",
  relationRequired: "Relation is required",

  dashboard: "Dashboard",
  openingStatement: "Start with adding your company first.",
  paymentMethod: "Payment Method",
  status: "Status",
  startDate: "Start Date",
  endDate: "End Date",
  cashRegister: "Cash Register",
  amount: "Amount",

  confirmationMessage: "Are you sure you want to close ?",
  revenueAdded: "Revenue is successfully saved",
  revenueClosed: "Revenue is successfully closed",
  revenueUpdate: "Revenue is successfully saved",
  companyAdded: "Company is successfully added",
  cashAdded: "Cash is successfully added in Register",
  cashUpdated: "Cash is successfully updated",
  relation: "Relation",
  openingBalance: "Opening Balance",
  closingBalance: "Closing Balance ",

  coda: "Coda tools",
  "csv-to-coda-accept":
    "Please wait until the converstion is completed. The result will be downloaded shortly.",
  "csv-to-coda-ready":
    "Drag & drop your CSV on top of me to convert it (or click me to open a file browser).",
  "csv-to-coda-reject": "You did not select a valid CSV file.",

  createdUsers: "Created users",
  administrators: "Accountants",
  linkedUsers: "Linked users",
  accountingFirms: "Accountants",
  linkedAdmins: "Linked accountants",
  inLinkRequests: "Link requests",
  outLinkRequests: "Link requests",
  companies: "Companies",
  control: "Manage",

  accountingFirmsHeading: "Accounting firms",
  email: "Email",
  linkStatus: "Status",
  unlinked: "Unlinked",
  linkRequestSent: "Link request sent",
  sendLinkRequest: "Send link request",
  linked: "Linked",

  administratorsHeading: "Accounting firms",

  manageCompany: "Manage",
  manageNoActions: "No actions available",
  manageHeading: "Manage",
  addUserToCompany: "Add user to company",
  emailVerified: "Email verified",
  actions: "Actions",
  yes: "Yes",
  no: "No",
  deleteUserFromCompanyConfirmation:
    "Are you sure want to delete the user from a company?",

  incomingLinkRequestsHeading: "Incoming link requests",
  cancelLinkRequestsConfirmation: "Are you sure want to cancel link request?",
  acceptLinkRequestConfirmation: "Are you sure want to accept link request?",
  acceptBtn: "Accept",
  cancelBtn: "Cancel",
  linkedAdminsHeading: "Linked accountants",
  unlinkBtn: "Unlink",
  unlinkConfirmation: "Are you sure want to unlink?",
  linkedUsersHeading: "Linked users",
  outcomingLinkRequestsHeading: "Outcoming link requests",
  cancelLinkRequestBtn: "Cancel",
  cancelLinkConfirmation: "Are you sure want to cancel link request?",

  manageUsersHeading: "Manage users",
  createUserHeading: "Create user",
  deleteUserConfirmation: "Are you sure want to delete user?",
  searchUserLabel: "Search user",
  searchUserPlaceholder: "Type user's name",
  firstName: "First name",
  lastName: "Last name",
  password: "Password",

  emailRequired: "Email is required",
  notValidEmail: "Email is not valid",
  firstNameRequired: "First name is required",
  lastNameRequired: "Last name is required",
  passwordRequired: "Password is required",
  passwordFormat:
    "Password must have at least 8 characters in length. At least one lower case (a-z), upper-case (A-Z), number (0-9) and special character (!@#$%^&*)",
  public: "Public",
  noCompaniesAssigned: "Your accountant has not assigned you a company yet",

  export: "Export",
  exportDailyRevenue: "Export daily revenue (.csv)",
  exportDailyRevenueDetail: "Export daily revenue VAT detail (.csv)",
};
