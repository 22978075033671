export const getErrorMessage = (error: any) => {
  if (error.response) {
    // Request made and server responded
    if (error.response.data.description) {
      return error.response.data.description;
    } else return `${JSON.stringify(error.response.data)}`;
  } else if (error.request) {
    // The request was made but no response was received
    return `No response from server`;
  } else {
    // Something happened in setting up the request that triggered an Error
    return `${error.message}`;
  }
};
