import { PaymentMethods, Dagontvangsten } from "../api/types";
import { getDatesOfMonthTillToday } from "./dateFormat";
import { getPaymentMethodName, getVatAmount } from "./paymentMethods";
import { isEqual } from "date-fns";

interface IDayRevenueLine
  extends Omit<Dagontvangsten.GetDagontvangsten.DagontvangstDto, "day"> {
  day: Date;
}

type IDataCalculation = {
  dataDisplay: IDayRevenueLine[];
  paymentMethods: PaymentMethods.GetPaymentMethodsForCompany.PaymentMethodDetail[];
  customRange?: {
    begin: Date;
    end: Date;
  };
  selectedDate?: Date;
};

type IRevenueBase = {
  date: Date;
  zeroPercent: number;
  sixPercent: number;
  twelvePercent: number;
  twentyPercent: number;
  daytotal: number;
  completed: boolean;
};
export interface IRevenueLine extends Omit<IRevenueBase, "completed"> {
  id?: number;
  description?: string;
  paymentMethod?: string;
  completed: boolean | undefined;
  hashId?: string;
}

export interface IDayRevenueSummary extends Omit<IRevenueBase, "completed"> {
  date: Date;
  history: IRevenueLine[];
  completed: boolean | null;
}

const appendLineToDayRevenu = (
  dayRevenue: IDayRevenueSummary,
  lines: IRevenueLine[]
) => {
  lines.forEach((line) => {
    if (!isEqual(dayRevenue.date, line.date)) {
      throw new Error(
        "The date of the appended revenue line does not match to the date of the day revenue to which it is added"
      );
    }
    dayRevenue.zeroPercent += line.zeroPercent;
    dayRevenue.sixPercent += line.sixPercent;
    dayRevenue.twelvePercent += line.twelvePercent;
    dayRevenue.twentyPercent += line.twentyPercent;
    dayRevenue.daytotal += line.daytotal;
    dayRevenue.history.push(line);
    dayRevenue.completed = dayRevenue.history.every((el) => el.completed);
  });
};

type IDayToDailyRevenueMapping = {
  [day: string]: IDayRevenueSummary;
};

export const dataCalculation = ({
  dataDisplay,
  paymentMethods,
  customRange,
  selectedDate = new Date(),
}: IDataCalculation): IDayRevenueSummary[] => {
  const datesToRender = getDatesOfMonthTillToday(selectedDate, customRange);

  // Initialize the mapping with empty values for each day that we need to render
  const dayToDailyRevenueMapping: IDayToDailyRevenueMapping = {};
  datesToRender.forEach((el) => {
    dayToDailyRevenueMapping[el.toJSON()] = {
      date: el,
      zeroPercent: 0,
      sixPercent: 0,
      twelvePercent: 0,
      twentyPercent: 0,
      daytotal: 0,
      history: [],
      completed: null,
    };
  });
  dataDisplay.forEach((line: IDayRevenueLine) => {
    const key = line.day.toJSON();
    if (dayToDailyRevenueMapping.hasOwnProperty(key)) {
      const dayHistory = line.vatDetails.map(
        ({
          description,
          vatRates,
          total,
          paymentMethodId,
          completed,
          hash,
          id,
        }) => ({
          date: line.day,
          description: description,
          zeroPercent: getVatAmount({ vatRates, vatRateId: 1 }),
          sixPercent: getVatAmount({ vatRates, vatRateId: 2 }),
          twelvePercent: getVatAmount({ vatRates, vatRateId: 3 }),
          twentyPercent: getVatAmount({ vatRates, vatRateId: 4 }),
          daytotal: total,
          paymentMethod: getPaymentMethodName({
            paymentMethods,
            paymentMethodId,
          }),
          completed: completed,
          hashId: hash || "",
          id: id,
        })
      );

      appendLineToDayRevenu(dayToDailyRevenueMapping[key], dayHistory);
    }
  });

  return datesToRender
    .map((date) => dayToDailyRevenueMapping[date.toJSON()])
    .reverse();
};
