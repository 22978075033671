import React, { createContext, useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContext } from "./snackbar";
import { useAxios } from "../utils/axiosConfig";
import {
  UserAccess,
  PaymentMethods,
  VatRates,
  Users,
  Companies,
} from "../api/types";
import { getErrorMessage } from "../utils/errorMessage";
import { Backdrop, CircularProgress } from "@mui/material";
import { useConfig } from "../config/useConfig";
import Alert from "@mui/material/Alert";
import i18n from "../locale/i18n";
import { getServerErrorMessages } from "../utils/getServerErrorsMessages";

type ProfileContextType = {
  profile: {
    sub: string;
    email: string;
    role: string[];
    language: string;
    publicProfile: boolean;
  };
  companyDetailsExist: boolean;
  companyDetailsLoading: boolean;
  accessToken: string;
  companiesDetailList: UserAccess.GetEverythingCurrentUserCanAccess.AccessDetail[];
  selectedCompanyID: number | null;
  changeCompany: (companyID: number) => void;
  paymentMethods: PaymentMethods.GetPaymentMethodsForCompany.PaymentMethodDetail[];
  vatRates: VatRates.GetVatRatesForCompany.VatRateDetail[];
  updateVatRates: (payload: Companies.UpdateVatRatesVisibility.Request) => {};
  updateUserLanguage: (language: string) => Promise<any>;
  hasRole: (roleNames: string[]) => boolean;
  openBackDrop: (open: boolean) => void;
  updateUserVisibility: (
    payload: Users.UpdateUserVisibility.Request
  ) => Promise<any>;
};

export const ProfileContext = createContext({} as ProfileContextType);

export enum Claims {
  ROLE = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
  LANGUAGE = "language",
  PUBLIC = "public",
}

export const ProfileProvider = ({ children }: any) => {
  const [profile, setProfile] = useState({
    sub: "",
    email: "",
    role: [""],
    language: "nl",
    publicProfile: false,
  });
  const [companiesDetailList, setcompaniesDetailList] = useState<
    UserAccess.GetEverythingCurrentUserCanAccess.AccessDetail[]
  >([]);
  const [selectedCompanyID, setSelectedCompanyID] =
    useState<number | null>(null);
  const { toastHandler } = useContext(ToastContext);
  const [companyDetailsExist, setCompanyDetailsExist] = useState(false);
  const [companyDetailsLoading, setCompanyDetailsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentMethods.GetPaymentMethodsForCompany.PaymentMethodDetail[]
  >([]);
  const [vatRates, setVatRates] = useState<
    VatRates.GetVatRatesForCompany.VatRateDetail[]
  >([]);
  const [backDropOpen, setBackDropOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    getAccessTokenSilently,
    loginWithPopup,
    logout,
    getAccessTokenWithPopup,
    user,
  } = useAuth0();
  const { apiOrigin, apiAudience, tenant } = useConfig();
  const { axiosHandler } = useAxios();

  useEffect(() => {
    if (accessToken) {
      checkCompanyDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (user && user.email && user.sub) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const initialize = async () => {
    if (!user || !user.email || !user.sub) return;
    const publicClaim = `https://${tenant}.com/${Claims.PUBLIC}`;
    const languageClaim = `https://${tenant}.com/${Claims.LANGUAGE}`;
    const publicProfile = user[publicClaim] ? user[publicClaim] : false;

    const newProfile = {
      sub: user.sub,
      email: user.email,
      role: user[Claims.ROLE] || [""],
      language: user[languageClaim],
      publicProfile: publicProfile,
    };

    setProfile(newProfile);

    i18n.changeLanguage(user[languageClaim]);
    await saveToken();
  };

  const saveToken = async () => {
    return await getAccessTokenSilently({
      audience: `${apiAudience}`,
    })
      .then((response) => {
        setAccessToken(response);
        return response;
      })
      .catch((e) => {
        console.error(e);
        logout({ returnTo: window.location.origin });
      });
  };

  const handleApiErrors = (error: string) => {
    if (error === "consent_required") {
      handleConsent();
    }
    if (error === "login_required") {
      handleLoginAgain();
    }
  };

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup({ audience: `${apiAudience}` });
    } catch (error: any) {
      toastHandler({
        toastMessage: error.error,
        toastType: "warning",
        toastVisible: true,
      });
    }
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
    } catch (error: any) {
      toastHandler({
        toastMessage: error.error,
        toastType: "warning",
        toastVisible: true,
      });
    }
  };

  const checkCompanyDetails = async () => {
    setBackDropOpen(true);
    setCompanyDetailsLoading(true);
    setErrorMessage(null);
    try {
      await axiosHandler(accessToken)
        .get<UserAccess.GetEverythingCurrentUserCanAccess.Response>(
          `/access/current`
        )
        .then(async (response) => {
          const { data } = response;
          if (response.status === 200) {
            if (data.accessDetails.length === 0) {
              setCompanyDetailsExist(false);
            } else {
              setCompanyDetailsExist(true);
              const companyID = data.accessDetails[0].companyId;
              if (data.accessDetails && companyID) {
                setcompaniesDetailList(data.accessDetails);
                const companyID = data.accessDetails[0].companyId;
                if (companyID) {
                  setSelectedCompanyID(companyID);
                  await getPaymentMethods(companyID);
                  await getVatRates(companyID);
                }
              }
            }
          }
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          for (let e of getServerErrorMessages(error)) {
            toastHandler(e);
          }
          setErrorMessage(errorMessage);
        });
    } catch (error) {
      handleApiErrors(error as string);
    } finally {
      setBackDropOpen(false);
      setCompanyDetailsLoading(false);
      setErrorMessage(null);
    }
  };

  const getPaymentMethods = async (companyId: number) => {
    try {
      await axiosHandler(accessToken)
        .get<PaymentMethods.GetPaymentMethodsForCompany.Response>(
          `${apiOrigin}/paymentmethods/${companyId}`
        )
        .then((response) => {
          const { data } = response;
          if (response.status === 200) {
            setPaymentMethods(data.paymentMethods);
          }
        })
        .catch((error) => {
          for (let e of getServerErrorMessages(error)) {
            toastHandler(e);
          }
        });
    } catch (error) {
      handleApiErrors(error as string);
    }
  };

  const getVatRates = async (companyId: number) => {
    try {
      await axiosHandler(accessToken)
        .get<VatRates.GetVatRatesForCompany.Response>(
          `${apiOrigin}/vatrates/${companyId}`
        )
        .then((response) => {
          const { data } = response;
          if (response.status === 200) {
            setVatRates(data.vatRates);
          }
        })
        .catch((error) => {
          for (let e of getServerErrorMessages(error)) {
            toastHandler(e);
          }
        });
    } catch (error) {
      handleApiErrors(error as string);
    }
  };

  const updateVatRates = async (
    payload: Companies.UpdateVatRatesVisibility.Request
  ) => {
    try {
      await axiosHandler(accessToken)
        .put<VatRates.GetVatRatesForCompany.Response>(
          `${apiOrigin}/vatrates/${payload.companyId}`,
          { ...payload }
        )
        .then((response) => {
          const { data } = response;
          if (response.status === 200) {
            updateVatRatesList(payload);
          }
        })
        .catch((error) => {
          for (let e of getServerErrorMessages(error)) {
            toastHandler(e);
          }
        });
    } catch (error) {
      for (let e of getServerErrorMessages(error)) {
        toastHandler(e);
      }
    }
  };

  const updateVatRatesList = (
    payload: Companies.UpdateVatRatesVisibility.Request
  ) => {
    if (selectedCompanyID !== payload.companyId) {
      return;
    }

    const updatedVatRatesList = vatRates.map((vr) => {
      var updatedVR = payload.vatRates.find((v) => v.id == vr.vatRateId);
      if (updatedVR) {
        return { ...vr, show: updatedVR.show };
      }
      return vr;
    });

    setVatRates(updatedVatRatesList);
  };

  const changeCompany = (companyID: number) => {
    setSelectedCompanyID(companyID);
  };

  const updateUserLanguage = async (language: string) => {
    setBackDropOpen(true);
    try {
      await axiosHandler(accessToken)
        .put<Users.UpdateUserLanguage.Response>(`${apiOrigin}/users/language`, {
          language: language,
        })
        .then(() => {
          setProfile({ ...profile, language: language });
        })
        .catch((error) => {
          for (let e of getServerErrorMessages(error)) {
            toastHandler(e);
          }
        });
    } catch (error) {
      for (let e of getServerErrorMessages(error)) {
        toastHandler(e);
      }
    } finally {
      setBackDropOpen(false);
    }
  };

  const updateUserVisibility = async (
    payload: Users.UpdateUserVisibility.Request
  ) => {
    try {
      await axiosHandler(accessToken)
        .put<Users.UpdateUserVisibility.Response>(
          `${apiOrigin}/users/visibility`,
          payload
        )
        .then(() => {
          setProfile({ ...profile, publicProfile: payload.isPublic });
        })
        .catch((error) => {
          for (let e of getServerErrorMessages(error)) {
            toastHandler(e);
          }
        });
    } catch (error) {
      for (let e of getServerErrorMessages(error)) {
        toastHandler(e);
      }
    }
  };

  const hasRole = (roleNames: string[]): boolean => {
    if (!profile || !profile.role || !profile.role.length) return false;

    for (var roleName of roleNames) {
      if (profile.role.indexOf(roleName) !== -1) {
        return true;
      }
    }

    return false;
  };

  const openBackDrop = (open: boolean) => {
    setBackDropOpen(open);
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        companyDetailsExist,
        companyDetailsLoading,
        accessToken,
        companiesDetailList,
        selectedCompanyID,
        changeCompany,
        paymentMethods,
        vatRates,
        updateVatRates,
        updateUserLanguage,
        hasRole,
        openBackDrop,
        updateUserVisibility,
      }}
    >
      <Backdrop style={{ zIndex: 1202 }} color="primary" open={backDropOpen}>
        {errorMessage ? (
          <Alert severity="error">{errorMessage}</Alert>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Backdrop>
      {user && accessToken && children}
    </ProfileContext.Provider>
  );
};
