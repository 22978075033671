import React from "react";
import Box from "@mui/material/Box";
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from "./DisplayRevenueData.module.scss";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useTranslation } from "react-i18next";
import i18n from "../../locale/i18n";
import { IDayRevenueSummary, IRevenueLine } from "../../utils/dataCalculation";
import { isSameDay } from "date-fns";
import { currencyFormat } from "../../utils/currencyFormat";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";

const Row = (props: {
  row: IDayRevenueSummary;
  selectedDate?: Date;
  lockIds?: ((extractId: number[]) => void | undefined) | undefined;
  setFullDayLock?: (date: Date) => void | undefined;
  showTableActions?: boolean;
}) => {
  const { row, selectedDate, lockIds, setFullDayLock, showTableActions } =
    props;
  const [open, setOpen] = React.useState(false);
  const { t: translate } = useTranslation();
  const [lockConfirmation, setLockConfirmation] = React.useState(false);
  const [saveSingleRowId, setSaveSingleRowId] = React.useState<number[]>([]);

  const handleClose = () => {
    setLockConfirmation(false);
  };
  const lockHandleOk = () => {
    if (saveSingleRowId.length > 0) {
      lockIds && lockIds(saveSingleRowId);
      setSaveSingleRowId([]);
    } else {
      const { date } = row;
      date && setFullDayLock && setFullDayLock(date);
    }

    setLockConfirmation(false);
  };

  return (
    <React.Fragment>
      <TableRow
        selected={selectedDate && isSameDay(selectedDate, row.date)}
        style={{
          backgroundColor: `${
            selectedDate && isSameDay(selectedDate, row.date)
              ? "#C3D8F1"
              : "#ffff"
          }`,
        }}
      >
        <TableCell>
          <IconButton
            disabled={row.history.length === 0}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.date.toLocaleDateString()}
        </TableCell>
        <TableCell>{currencyFormat(row.zeroPercent)}</TableCell>
        <TableCell>{currencyFormat(row.sixPercent)}</TableCell>
        <TableCell>{currencyFormat(row.twelvePercent)}</TableCell>
        <TableCell>{currencyFormat(row.twentyPercent)}</TableCell>
        <TableCell>{currencyFormat(row.daytotal)}</TableCell>

        {showTableActions && (
          <TableCell>
            {row.completed ? (
              <LockIcon color="primary" />
            ) : (
              <Tooltip arrow title={translate("lockFulldayTooltip") ?? ""}>
                <LockOpenIcon
                  color="secondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => setLockConfirmation(true)}
                />
              </Tooltip>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: "5px",
            paddingTop: "5px",
            backgroundColor: "#fafafa",
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography gutterBottom component="div">
                {translate("history")}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>{translate("date")}</TableCell>
                    <TableCell>{translate("description")}</TableCell>
                    <TableCell>{translate("paymentMethod")}</TableCell>
                    <TableCell>0%</TableCell>
                    <TableCell>6%</TableCell>
                    <TableCell>12%</TableCell>
                    <TableCell>21%</TableCell>
                    <TableCell>{translate("total")} </TableCell>
                    {showTableActions && (
                      <TableCell>{translate("status")}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map(
                    (
                      {
                        id,
                        date,
                        description,
                        zeroPercent,
                        sixPercent,
                        twelvePercent,
                        twentyPercent,
                        daytotal,
                        paymentMethod,
                        completed,
                        hashId,
                      }: IRevenueLine,
                      index
                    ) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {date.toLocaleDateString()}
                        </TableCell>
                        <TableCell>{description}</TableCell>
                        <TableCell>{paymentMethod}</TableCell>
                        <TableCell>{currencyFormat(zeroPercent)}</TableCell>
                        <TableCell>{currencyFormat(sixPercent)}</TableCell>
                        <TableCell>{currencyFormat(twelvePercent)}</TableCell>
                        <TableCell>{currencyFormat(twentyPercent)}</TableCell>
                        <TableCell>{currencyFormat(daytotal)}</TableCell>
                        {showTableActions && (
                          <TableCell>
                            {completed ? (
                              <Tooltip arrow title={`${hashId}`}>
                                <LockIcon
                                  style={{ cursor: "pointer" }}
                                  color="primary"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                arrow
                                title={translate("lockLineTooltip") ?? ""}
                              >
                                <LockOpenIcon
                                  style={{ cursor: "pointer" }}
                                  color="secondary"
                                  onClick={() => {
                                    id && setSaveSingleRowId([id]);
                                    setLockConfirmation(true);
                                  }}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={lockConfirmation}
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={lockHandleOk}
        title={translate("confirmationMessage")}
      />
    </React.Fragment>
  );
};

const generateReducer = (propertyName: keyof IDayRevenueSummary) => {
  return function sumReducer(
    prevValue: number,
    currentValue: IDayRevenueSummary
  ) {
    return prevValue + (currentValue[propertyName] as number);
  };
};

const DisplayRevenueData = ({
  dataDisplay,
  selectedDate,
  lockIds,
  setFullDayLock,
  showTableActions = true,
}: {
  dataDisplay: IDayRevenueSummary[];
  selectedDate?: Date;
  lockIds?: (id: number[]) => void | undefined;
  setFullDayLock?: (date: Date) => void | undefined;
  showTableActions?: boolean;
}) => {
  const total = {
    zeroPercent: dataDisplay.reduce(generateReducer("zeroPercent"), 0),
    sixPercent: dataDisplay.reduce(generateReducer("sixPercent"), 0),
    twelvePercent: dataDisplay.reduce(generateReducer("twelvePercent"), 0),
    twentyPercent: dataDisplay.reduce(generateReducer("twentyPercent"), 0),
    rowTotal: dataDisplay.reduce(generateReducer("daytotal"), 0),
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={styles.revenueTable}>
              <TableCell />
              <TableCell>{i18n.t("date")}</TableCell>
              <TableCell>0%</TableCell>
              <TableCell>6%</TableCell>
              <TableCell>12%</TableCell>
              <TableCell>21%</TableCell>
              <TableCell>{i18n.t("dayTotal")}</TableCell>
              {showTableActions && <TableCell>{i18n.t("status")}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles.totalRow}>
              <TableCell></TableCell>
              <TableCell component="th" scope="row">
                {i18n.t("dayTotal")}
              </TableCell>
              <TableCell>{currencyFormat(total.zeroPercent)}</TableCell>
              <TableCell>{currencyFormat(total.sixPercent)}</TableCell>
              <TableCell>{currencyFormat(total.twelvePercent)}</TableCell>
              <TableCell>{currencyFormat(total.twentyPercent)}</TableCell>
              <TableCell>{currencyFormat(total.rowTotal)}</TableCell>
              {showTableActions && <TableCell />}
            </TableRow>
            {dataDisplay.map((row, index: number) => (
              <Row
                key={index}
                row={row}
                selectedDate={selectedDate}
                lockIds={lockIds}
                setFullDayLock={setFullDayLock}
                showTableActions={showTableActions}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DisplayRevenueData;
