import { TextField as MuiTextField } from "@mui/material";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";
import React from "react";
import { NumberFormatCustom } from "./NumberFormat";

const FormikMoneyField = (props: TextFieldProps) => {
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const parsed = parseFloat(e.target.value);
      props.form.setFieldValue(
        props.field.name,
        Number.isNaN(parsed) ? 0 : e.target.value
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.field.name, props.form.setFieldValue, props.onChange]
  );

  return (
    <MuiTextField
      {...fieldToTextField(props)}
      inputRef={props.inputRef}
      disabled={props.disabled}
      variant="outlined"
      style={{ marginBottom: "1rem", ...props.style }}
      fullWidth
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  );
};

export default FormikMoneyField as React.ComponentType;
