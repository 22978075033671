import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGE_ENGLISH } from "./en";
import { LANGUAGE_DUTCH } from "./nl";

const i18nInitializer = async () => {
  await i18n.use(initReactI18next).init({
    resources: {
      nl: {
        translation: LANGUAGE_DUTCH,
      },
      en: {
        translation: LANGUAGE_ENGLISH,
      },
    },
    supportedLngs: ["nl", "en"],
    // lng: "en",
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      useSuspense: false,
    },
  });
};

i18nInitializer();
export default i18n;
