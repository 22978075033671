import React from "react";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { TextField } from "@mui/material";

const FormikDateField = ({
  form: { setFieldValue },
  field: { value, name },
  inputRef,
  ...props
}: any) => {
  return (
    <DesktopDatePicker
      {...props}
      inputRef={inputRef}
      clearable="true"
      autoOk
      disableFuture
      fullWidth
      inputFormat="dd/MM/yyyy"
      onChange={(date: Date) => {
        if (date) {
          setFieldValue(name, date);
        }
      }}
      inputVariant="outlined"
      value={value}
      animateYearScrolling={false}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          style={{ marginBottom: "1rem", ...props.style }}
        />
      )}
    />
  );
};

export default FormikDateField as React.ComponentType;
