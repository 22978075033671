import React, { useContext } from "react";
import { MenuItem, FormControl, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import styles from "./Selections.module.scss";
import { ProfileContext } from "../../providers/profile";
import { UserAccess } from "../../api/types";

const Selections = () => {
  const { companiesDetailList, changeCompany } = useContext(ProfileContext);

  const handleChange = (event: SelectChangeEvent<number | null>) => {
    changeCompany(event.target.value as number);
  };

  return (
    <div className={styles.selections}>
      <FormControl className={styles.selectionsForm}>
        {companiesDetailList && companiesDetailList.length > 1 ? (
          <div className={styles.companyField}>
            <Select
              labelId="company-select-label"
              id="company-select"
              className={styles.select}
              onChange={handleChange}
              //value={selectedCompanyID}
              defaultValue={companiesDetailList[0].companyId}
            >
              {companiesDetailList &&
                companiesDetailList.length > 0 &&
                companiesDetailList.map(
                  ({
                    companyId,
                    companyName,
                  }: UserAccess.GetEverythingCurrentUserCanAccess.AccessDetail) => {
                    return (
                      companyId && (
                        <MenuItem key={companyId} value={companyId}>
                          {companyName}
                        </MenuItem>
                      )
                    );
                  }
                )}
            </Select>
          </div>
        ) : (
          <div className={styles.companyField}>
            <Typography variant="body1" gutterBottom component="div">
              {companiesDetailList &&
                companiesDetailList.length > 0 &&
                companiesDetailList[0].companyName}
            </Typography>
          </div>
        )}
      </FormControl>
    </div>
  );
};

export default Selections;
