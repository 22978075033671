import React from "react";
import { Routes, Route } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticatedApp from "./AuthenticatedApp";
import Info from "./Info";
import { Backdrop, CircularProgress } from "@mui/material";
import companyLogo from "./assets/Kmodo_full_cropped.png";

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return (
      <Backdrop
        style={{ zIndex: 1202, color: "black", backgroundColor: "white" }}
        open={true}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt="company-logo" src={companyLogo} width="20%" />
          <br />
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
    );
  } else if (!isAuthenticated) {
    return <>{loginWithRedirect()}</>;
  } else {
    return (
      <Routes>
        <Route path="*" element={<AuthenticatedApp />} />
        <Route path="/info" element={Info} />
      </Routes>
    );
  }
}

export default App;
