import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import {
  Box,
  ListSubheader,
  SvgIconTypeMap,
  useMediaQuery,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import HeaderBar from "../Header/Header";
import { navigationLinks } from "./navigationLinks";
import ListItemLink from "../ListItemLink";
import styles from "./Layout.module.scss";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../config/useConfig";
import { KmodoLogoSmall, KmodoLogoLarge } from "../Logos";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ProfileContext } from "../../providers/profile";

const Layout = () => {
  const [desktopOpen, setDesktopOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t: translate } = useTranslation();
  const config = useConfig();
  const isMobileOrTablet = useMediaQuery("(max-width:600px)");
  const linksToHide = config.cashRegister ? [] : ["cashRegister"];
  const { hasRole } = useContext(ProfileContext);

  useEffect(() => {
    if (isMobileOrTablet) {
      if (desktopOpen) {
        setDesktopOpen(false);
      }
    } else {
      if (!desktopOpen) {
        setDesktopOpen(true);
        setMobileOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileOrTablet]);

  const handleDrawerToggle = () => {
    if (isMobileOrTablet) {
      setMobileOpen(!mobileOpen);
    } else {
      setDesktopOpen(!desktopOpen);
    }
  };

  const DrawerContent = () => {
    return (
      <>
        <Box
          className={styles.toolbar}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "70px",
          }}
        >
          <div className={desktopOpen ? styles.show : styles.hide}>
            <KmodoLogoLarge />
          </div>
          {!isMobileOrTablet && (
            <div className={desktopOpen ? styles.hide : styles.show}>
              <KmodoLogoSmall />
            </div>
          )}
        </Box>
        <List>
          <div className={styles.drawerContainer}>
            <List>
              {navigationLinks
                .filter((link) => !linksToHide.includes(link.primaryId))
                .map(({ icon, to, primaryId, role, isSubHeader }, index) => {
                  if (role.length === 0 || hasRole(role)) {
                    if (isSubHeader)
                      return (
                        <ListSubheader>{translate(primaryId)}</ListSubheader>
                      );
                    else
                      return (
                        <ListItemLink
                          icon={
                            React.isValidElement(icon)
                              ? icon
                              : React.createElement(
                                  icon as OverridableComponent<
                                    SvgIconTypeMap<{}, "svg">
                                  > & {
                                    muiName: string;
                                  }
                                )
                          }
                          to={to}
                          primary={translate(primaryId)}
                          selectedLink={to}
                          key={index}
                          onClickListItem={
                            isMobileOrTablet
                              ? () => setMobileOpen(false)
                              : () => {}
                          }
                        />
                      );
                  } else {
                    return null;
                  }
                })}
            </List>
          </div>
        </List>
      </>
    );
  };

  const MobileDrawer = () => {
    return (
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: { width: "70%" },
        }}
      >
        <DrawerContent />
      </Drawer>
    );
  };

  const DesktopDrawer = () => {
    return (
      <Drawer
        variant={"permanent"}
        className={clsx(styles.drawer, {
          [styles.drawerOpen]: desktopOpen,
          [styles.drawerClose]: !desktopOpen,
        })}
        classes={{
          paper: clsx({
            [styles.drawerOpen]: desktopOpen,
            [styles.drawerClose]: !desktopOpen,
          }),
        }}
      >
        <DrawerContent />
      </Drawer>
    );
  };

  return (
    <div className={styles.root}>
      <CssBaseline />
      <HeaderBar open={desktopOpen} handleDrawerToggle={handleDrawerToggle} />
      <MobileDrawer />
      <DesktopDrawer />
    </div>
  );
};

export default Layout;
