import { format, startOfMonth, endOfMonth } from "date-fns";
import { useState, useContext, useEffect } from "react";
import { Dagontvangsten } from "../api/types";
import { useAxios } from "../utils/axiosConfig";
import { yearDateFormat } from "../utils/dateFormat";
import { ProfileContext } from "./profile";
import { ToastContext } from "./snackbar";
import { useTranslation } from "react-i18next";
import { getServerErrorMessages } from "../utils/getServerErrorsMessages";

export const useDailyRevenue = () => {
  const { selectedCompanyID } = useContext(ProfileContext);
  const { t: translate } = useTranslation();
  const { toastHandler } = useContext(ToastContext);
  const { axiosHandler } = useAxios();
  const [dailyRevenueData, setDailyRevenue] = useState<any>({});
  const [selectedMonthRevenueData, setSelectedMonthRevenueData] = useState<any>(
    []
  );
  const [customRangeRevenueData, setCustomRangeRevenueData] = useState<any>([]);
  const { openBackDrop } = useContext(ProfileContext) || {};

  useEffect(() => {
    if (selectedCompanyID) {
      fetchCurrentMonthDailyRevenue({
        companyId: selectedCompanyID,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyID]);

  const syncDates = (day: string) => {
    const getCurrentMonth = format(new Date(), "LLLL");
    const getMonth = format(new Date(day), "LLLL");
    if (getMonth === getCurrentMonth) {
      selectedCompanyID &&
        fetchDailyRevenueByDate({
          date: format(new Date(day), "yyyy-MM-dd"),
          companyId: selectedCompanyID,
        });
      selectedCompanyID &&
        fetchCurrentMonthDailyRevenue({
          companyId: selectedCompanyID,
        });
    } else {
      const startDate = startOfMonth(new Date(day));
      const endDate = endOfMonth(new Date(day));
      selectedCompanyID &&
        fetchCustomRangeDailyRevenue({
          companyId: selectedCompanyID,
          begin: yearDateFormat(startDate),
          end: yearDateFormat(endDate),
          showHistory: false,
        });
      selectedCompanyID &&
        fetchDailyRevenueByDate({
          date: format(new Date(day), "yyyy-MM-dd"),
          companyId: selectedCompanyID,
        });
    }
  };

  const parseRevenueLines = (apiResponse: any) =>
    apiResponse.data.dagontvangsten.map((el: any) => ({
      ...el,
      day: new Date(el.day),
    }));

  const fetchDailyRevenueByDate = async ({
    date,
    companyId,
  }: {
    date: string;
    companyId: number;
  }) => {
    openBackDrop(true);
    await axiosHandler()
      .get(`/dagontvangsten/by-day/${companyId}?day=${date}`)
      .then((response) => {
        if (response.status === 200) {
          setDailyRevenue(response.data);
        }
        if (response.status === 204) {
          setDailyRevenue({});
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const fetchCurrentMonthDailyRevenue = async ({
    companyId,
  }: {
    companyId: number;
  }) => {
    openBackDrop(true);
    await axiosHandler()
      .get(`/dagontvangsten/current/${companyId}`)
      .then((response) => {
        if (response.status === 200) {
          response.data &&
            setSelectedMonthRevenueData(parseRevenueLines(response));
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const addDailyRevenue = async (
    payload: Dagontvangsten.AddDagontvangst.Request
  ) => {
    openBackDrop(true);
    await axiosHandler()
      .post<Dagontvangsten.AddDagontvangst.Response>("/dagontvangst", {
        ...payload,
      })
      .then((response) => {
        if (response.status === 200) {
          toastHandler({
            toastVisible: true,
            toastMessage: translate("revenueAdded"),
            toastType: "success",
          });
          if (selectedCompanyID) {
            const { day } = payload;
            syncDates(day);
          }
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const updateDailyRevenue = async (
    payload: Dagontvangsten.UpdateDagontvangst.Request,
    day: string
  ) => {
    openBackDrop(true);
    await axiosHandler()
      .put<Dagontvangsten.UpdateDagontvangst.Response>(
        `/dagontvangst/${payload.id}`,
        { ...payload }
      )
      .then((response) => {
        if (response.status === 200) {
          toastHandler({
            toastVisible: true,
            toastMessage: translate("revenueUpdate"),
            toastType: "success",
          });
          if (selectedCompanyID) {
            syncDates(day);
          }
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const fetchCustomRangeDailyRevenue = async ({
    companyId,
    begin,
    end,
    showHistory,
  }: {
    companyId: number;
    begin: string;
    end: string;
    showHistory: boolean;
  }) => {
    openBackDrop(true);
    await axiosHandler()
      .get(`/dagontvangsten/${companyId}/company?Begin=${begin}&End=${end}`)
      .then((response) => {
        if (response.status === 200) {
          response.data &&
            showHistory &&
            setCustomRangeRevenueData(parseRevenueLines(response));
          response.data &&
            !showHistory &&
            setSelectedMonthRevenueData(parseRevenueLines(response));
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const generateVATRateReport = async ({
    companyId,
    begin,
    end,
    reportName,
  }: {
    companyId: number;
    begin: string;
    end: string;
    showHistory: boolean;
    reportName: string;
  }) => {
    openBackDrop(true);
    await axiosHandler()
      .get(
        `/dagontvangsten/period/${companyId}/company/vatrates-export?Begin=${begin}&End=${end}`
      )
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: "data:text/csv;charset=utf-8,",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${reportName}.csv`;
          link.click();
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const generateVATDetailReport = async ({
    companyId,
    begin,
    end,
    reportName,
  }: {
    companyId: number;
    begin: string;
    end: string;
    showHistory: boolean;
    reportName: string;
  }) => {
    openBackDrop(true);
    await axiosHandler()
      .get(
        `/dagontvangsten/period/${companyId}/company/vatdetails-export?Begin=${begin}&End=${end}`
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "data:text/csv;charset=utf-8,",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${reportName}.csv`;
        link.click();
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const closeMultipleRecords = async (
    payload: Dagontvangsten.CompleteVatDetails.Request,
    day: string
  ) => {
    openBackDrop(true);
    await axiosHandler()
      .put<Dagontvangsten.CompleteVatDetails.Response>(
        `/dagontvangsten/details/complete`,
        { ...payload }
      )
      .then((response) => {
        if (response.status === 200) {
          toastHandler({
            toastVisible: true,
            toastMessage: translate("revenueClosed"),
            toastType: "success",
          });
          if (selectedCompanyID) {
            syncDates(day);
          }
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const closeMultipleEmptyRecords = async ({
    dayToComplete,
    userSelectedDate,
  }: {
    dayToComplete: string;
    userSelectedDate: string;
  }) => {
    openBackDrop(true);
    await axiosHandler()
      .put<Dagontvangsten.CompleteDagontvangst.Response>(
        `/dagontvangsten/complete/${selectedCompanyID}?dayToComplete=${dayToComplete}`
      )
      .then(() => {
        toastHandler({
          toastVisible: true,
          toastMessage: translate("revenueClosed"),
          toastType: "success",
        });
        syncDates(userSelectedDate);
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  return {
    dailyRevenueData,
    selectedMonthRevenueData,
    customRangeRevenueData,
    addDailyRevenue,
    updateDailyRevenue,
    fetchDailyRevenueByDate,
    fetchCurrentMonthDailyRevenue,
    fetchCustomRangeDailyRevenue,
    closeMultipleRecords,
    generateVATRateReport,
    generateVATDetailReport,
    closeMultipleEmptyRecords,
  };
};
