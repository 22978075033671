import React from "react";
import {
  PaymentsOutlined,
  People,
  PointOfSaleOutlined,
  Store,
} from "@mui/icons-material";
import codaboxImage from "../../assets/thirdparty/codabox.png";
import { ACCOUNTANT_ADMIN, USER_INDEPENDENT } from "../../api/constants";

export const navigationLinks = [
  {
    icon: PaymentsOutlined,
    to: "/daily-revenue",
    primaryId: "dailyRevenue",
    isSubHeader: false,
    role: [],
  },
  {
    icon: PointOfSaleOutlined,
    to: "/cash-register",
    primaryId: "cashRegister",
    isSubHeader: false,
    role: [],
  },
  {
    icon: null,
    to: "",
    primaryId: "control",
    isSubHeader: true,
    role: [ACCOUNTANT_ADMIN, USER_INDEPENDENT],
  },
  {
    icon: People,
    to: "/users",
    primaryId: "createdUsers",
    isSubHeader: false,
    role: [ACCOUNTANT_ADMIN],
  },
  {
    icon: Store,
    to: "/company",
    primaryId: "companies",
    isSubHeader: false,
    role: [ACCOUNTANT_ADMIN, USER_INDEPENDENT],
  },
  {
    icon: (
      <img alt="codabox-logo" src={codaboxImage} width="25px" height="25px" />
    ),
    to: "/coda",
    primaryId: "coda",
    isSubHeader: false,
    role: [ACCOUNTANT_ADMIN],
  },
];
