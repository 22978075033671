import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { fieldToTextField, TextFieldProps } from "formik-material-ui";

const FormikTextField = (props: TextFieldProps) => {
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      inputRef={props.inputRef}
      variant="outlined"
      fullWidth
      style={{ marginBottom: "1rem" }}
    />
  );
};

export default FormikTextField as React.ComponentType;
