import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { TextFieldProps, fieldToTextField } from "formik-material-ui";

const FormikSelectField = (props: TextFieldProps) => {
  const value = props.field.value || "";
  const setValue = (value: string) => {
    props.form.setFieldValue(props.field.name, value);
  };

  return (
    <MuiTextField
      {...fieldToTextField(props)}
      inputRef={props.inputRef}
      value={value}
      select={true}
      variant="outlined"
      onChange={(e) => setValue(e.target.value)}
      fullWidth={true}
      style={{ marginBottom: "1rem", ...props.style }}
    >
      {props.children}
    </MuiTextField>
  );
};

export default FormikSelectField as React.ComponentType;
