import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  title: any;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { t: translate } = useTranslation();

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          {translate("cancel")}
        </Button>
        <Button onClick={props.onConfirm} color="primary" autoFocus>
          {translate("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
