import { Global } from "@emotion/react";
import { Add, Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  ListItemIcon,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  Zoom,
} from "@mui/material";
import {
  Field,
  FieldArray,
  Form,
  Formik,
  getIn,
  useFormikContext,
} from "formik";
import { default as React, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Dagontvangsten, PaymentMethods, VatRates } from "../../api/types";
import { currencyFormat } from "../../utils/currencyFormat";
import { PaymentMethodsIcons } from "../../utils/paymentMethods";
import FormikMoneyField from "../forms/FormikMoneyField";
import FormikSelectField from "../forms/FormikSelectField";
import styles from "./DailyRevenueInput.module.scss";

type DailyRevenueInputProps = {
  paymentMethods: PaymentMethods.GetPaymentMethodsForCompany.PaymentMethodDetail[];
  vatRates: VatRates.GetVatRatesForCompany.VatRateDetail[];
  onSave: (
    data: any,
    close: boolean,
    callbackFn?: (done: boolean) => void
  ) => void;
  values: { vatDetails: Dagontvangsten.VatDetailDto[] };
};

const validationSchema = Yup.object({
  vatDetails: Yup.array()
    .of(
      Yup.object().shape({
        vatRates: Yup.array().of(
          Yup.object().shape({
            vatRateId: Yup.number().required(),
            amountVatRate: Yup.number().required("Required"),
          })
        ),
        paymentMethodId: Yup.number().nullable(true),
        description: Yup.string().optional(),
        total: Yup.number(),
        hash: Yup.string(),
        completed: Yup.boolean(),
      })
    )
    .required()
    .min(1, "Need at least 1 line"),
});

const TotalField = ({
  name,
  line,
}: {
  name: string;
  line: Dagontvangsten.VatDetailDto;
}) => {
  const { t: translate } = useTranslation();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const lineTotal = line.vatRates.reduce(
      (accum, cur) => accum + cur.amountVatRate,
      0
    );
    setFieldValue(name, lineTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line.vatRates, name]);

  return (
    <Field
      name={name}
      type="text"
      component={FormikMoneyField}
      label={translate("total")}
      disabled={true}
    />
  );
};

const DailyRevenueInput = ({
  paymentMethods,
  vatRates,
  onSave,
  values,
}: DailyRevenueInputProps) => {
  const { t: translate } = useTranslation();
  const isMobileOrTablet = useMediaQuery("(max-width:600px)");
  const bottomOfScreen = useRef<null | HTMLDivElement>(null);

  if (!Array.isArray(values.vatDetails) || values.vatDetails.length === 0) {
    values.vatDetails = [
      {
        id: -1,
        paymentMethodId: null,
        paymentMethodName: "",
        description: "",
        total: 0,
        hash: "",
        completed: false,
        vatRates: vatRates.map((rate) => ({
          vatRateId: rate.vatRateId,
          amountVatRate: 0,
          rate: 0,
        })),
      },
    ];
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={values}
      onSubmit={(values, { setSubmitting }) => {
        onSave(values, false, setSubmitting);
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form>
          <FieldArray
            name="vatDetails"
            render={(arrayHelpers) => (
              <>
                {values.vatDetails &&
                  values.vatDetails.map(
                    (line: Dagontvangsten.VatDetailDto, index: number) => {
                      return (
                        <Box key={index}>
                          <Grid
                            container
                            spacing={1}
                            sx={{ my: 0, alignItems: "baseline" }}
                          >
                            <Grid item xs={12} sm={6} md={2} mb={{ xs: 1 }}>
                              <Field name={`vatDetails[${index}].description`}>
                                {({
                                  field,
                                  meta,
                                }: {
                                  field: any;
                                  meta: any;
                                }) => (
                                  <TextField
                                    {...field}
                                    disabled={line.completed}
                                    fullWidth
                                    label={translate("description")}
                                    required={false}
                                    error={meta.touched && Boolean(meta.error)}
                                    variant="outlined"
                                    type="text"
                                    inputProps={{
                                      autoComplete: "off",
                                      "data-lpignore": "true",
                                    }}
                                    // error={getIn(touched, `vatDetails[${index}].description`) && Boolean(getIn(errors, `vatDetails[${index}].description`))}
                                  />
                                )}
                              </Field>
                            </Grid>

                            <Grid item xs={12} sm={6} md={2}>
                              <Global
                                styles={{
                                  "&.MuiSelect-outlined": {
                                    alignItems: "center !important",
                                    display: "flex !important",
                                    padding: line?.paymentMethodId
                                      ? "0.88rem !important"
                                      : "inherit",
                                  },
                                }}
                              />
                              <Field
                                name={`vatDetails.${index}.paymentMethodId`}
                                type="text"
                                component={FormikSelectField}
                                label={translate("payment")}
                                disabled={line.completed}
                                error={
                                  getIn(
                                    touched,
                                    `vatDetails[${index}].paymentMethodId`
                                  ) &&
                                  Boolean(
                                    getIn(
                                      errors,
                                      `vatDetails[${index}].paymentMethodId`
                                    )
                                  )
                                }
                              >
                                <MenuItem value="" key="0">
                                  --
                                </MenuItem>
                                {paymentMethods
                                  .sort((a, b) => a.order - b.order)
                                  .filter(({ show }) => show === true)
                                  .map((payment) => (
                                    <MenuItem
                                      key={payment.paymentMethodId}
                                      value={payment.paymentMethodId}
                                      sx={{
                                        alignItems: "center",
                                      }}
                                      divider={true}
                                    >
                                      <ListItemIcon style={{ minWidth: 0 }}>
                                        <img
                                          alt={`payment-method-logo-${payment.paymentMethodName}`}
                                          src={
                                            PaymentMethodsIcons[
                                              payment.paymentMethodName
                                            ]
                                          }
                                          width="35px"
                                          height="28px"
                                        />
                                      </ListItemIcon>
                                      <Typography
                                        sx={{ marginLeft: 1 }}
                                        variant="inherit"
                                      >
                                        {payment.paymentMethodName}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                              </Field>
                            </Grid>

                            {Array.isArray(vatRates) &&
                              vatRates.map((rate) => {
                                const nameKey =
                                  values.vatDetails[index].vatRates &&
                                  values.vatDetails[index].vatRates.find(
                                    (item) => item.vatRateId === rate.vatRateId
                                  );
                                return (
                                  nameKey && (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={3}
                                      md={1}
                                      key={`vatRates.${index}.${rate.vatRateId}`}
                                    >
                                      <div style={{ width: "100%" }}>
                                        <Field
                                          className={styles.mobileSliderRow}
                                          disabled={
                                            line.completed || !rate.show
                                          }
                                          name={`vatDetails.${index}.vatRates.${
                                            nameKey?.vatRateId - 1
                                          }.amountVatRate`}
                                          type="text"
                                          component={FormikMoneyField}
                                          label={rate.vatRateName}
                                        />
                                      </div>
                                    </Grid>
                                  )
                                );
                              })}

                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={2}
                              container
                              sx={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              spacing={3}
                            >
                              <Grid item xs={10}>
                                <TotalField
                                  name={`vatDetails.${index}.total`}
                                  line={line}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                container
                                sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexWrap: "nowrap",
                                  flexDirection: "column",
                                  mb: "1rem",
                                }}
                              >
                                <Tooltip
                                  arrow
                                  TransitionComponent={Zoom}
                                  title={translate("delete") ?? "Verwijder"}
                                >
                                  <Grid item xs="auto">
                                    <Button
                                      aria-label="Delete Row"
                                      variant="outlined"
                                      sx={{ padding: "5px" }}
                                      onClick={() => arrayHelpers.remove(index)}
                                      size="small"
                                      style={{ minWidth: 0 }}
                                      disabled={
                                        values.vatDetails.length <= 1 ||
                                        line.completed
                                      }
                                    >
                                      <Clear />
                                    </Button>
                                  </Grid>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider
                            sx={{
                              display: "block",
                              mb: 1,
                            }}
                          />
                        </Box>
                      );
                    }
                  )}

                <div
                  id="bottomOfList"
                  ref={bottomOfScreen as React.RefObject<HTMLDivElement>}
                />

                <Grid
                  container
                  spacing={1}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mb: 1,
                    mt: 1,
                    pb: isMobileOrTablet ? 0 : 0,
                    position: isMobileOrTablet ? "sticky" : "relative",
                    bottom: isMobileOrTablet ? 0 : "inherit",
                    background: isMobileOrTablet ? "#fff" : "inherit",
                    zIndex: 100,
                  }}
                >
                  <Grid xs={10} sm={9} md={4} item>
                    <Button
                      sx={{ marginRight: 1 }}
                      aria-label="Add Row"
                      variant="outlined"
                      color="primary"
                      disabled={isSubmitting}
                      size={"medium"}
                      startIcon={<Add />}
                      onClick={() => {
                        const clientVatRate: {
                          vatRateId: any;
                          amountVatRate: number;
                        }[] = [];
                        vatRates.forEach((rate: any) =>
                          clientVatRate.push({
                            vatRateId: rate.vatRateId,
                            amountVatRate: 0,
                          })
                        );

                        arrayHelpers.push({
                          completed: false,
                          total: 0,
                          paymentMethodId: "",
                          description: "",
                          vatRates: clientVatRate,
                        });

                        if (isMobileOrTablet) {
                          setTimeout(() => {
                            bottomOfScreen.current?.scrollIntoView({
                              behavior: "smooth",
                            });
                          }, 100);
                        }
                      }}
                    >
                      {translate("addRow")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                      size={"medium"}
                    >
                      {isSubmitting && (
                        <CircularProgress
                          size={15}
                          color="secondary"
                          className={styles.loadingSpinner}
                        />
                      )}
                      {translate("saveWholeDay")}
                    </Button>
                  </Grid>

                  {Array.isArray(vatRates) &&
                    vatRates.map((rate, index) => {
                      return (
                        <Grid
                          item
                          xs={3}
                          md={1}
                          key={`vatRateToggles.${index}.${rate.vatRateId}`}
                          sx={{
                            display: {
                              xs: "none",
                              md: "block",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "bolder",
                              fontSize: "0.9rem",
                            }}
                          >
                            {currencyFormat(
                              values.vatDetails.reduce(
                                (accumulator, dagontvangst) => {
                                  const rateAmount =
                                    dagontvangst?.vatRates?.find(
                                      (vatRate) =>
                                        vatRate?.vatRateId === rate?.vatRateId
                                    )?.amountVatRate || 0;

                                  return accumulator + rateAmount;
                                },
                                0
                              )
                            )}
                          </Box>
                        </Grid>
                      );
                    })}

                  <Grid
                    item
                    xs={2}
                    md={1.5}
                    sx={{
                      display: {
                        xs: "block",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bolder",
                        fontSize: "0.9rem",
                      }}
                    >
                      {translate("dayTotal")}:{" "}
                      {currencyFormat(
                        values.vatDetails.reduce(
                          (accumulator, dagontvangst) => {
                            const totalAmount = dagontvangst?.total || 0;

                            return accumulator + totalAmount;
                          },
                          0
                        )
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};
export default DailyRevenueInput;
