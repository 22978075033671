import React, { useState, useEffect } from "react";
import { useConfig } from "./config/useConfig";
import { useAxios } from "./utils/axiosConfig";

function Version() {
  const { axiosHandler } = useAxios();
  const [backendVersion, setBackendVersion] = useState("");
  const config = { ...useConfig(), backend_version: backendVersion };

  useEffect(() => {
    axiosHandler()
      .get(`/version`)
      .then((response) => setBackendVersion(response.data))
      .catch((e) => setBackendVersion(e.message));
  });

  return (
    <div>
      <pre>{JSON.stringify(config, null, 2)}</pre>
    </div>
  );
}

export default Version;
