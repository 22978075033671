import { Close } from "@mui/icons-material";
import { Box, IconButton, styled, SwipeableDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

type BottomSheetInputProps = {
  isBottomSheetOpen: boolean;
  toggleBottomSheet: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  drawerBleeding: number;
};

const BottomSheet: React.FC<BottomSheetInputProps> = ({
  isBottomSheetOpen,
  toggleBottomSheet,
  drawerBleeding,
  children,
}) => {
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
  }));

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)",
  }));

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor={"bottom"}
      open={isBottomSheetOpen}
      onClose={toggleBottomSheet(false)}
      onOpen={toggleBottomSheet(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <StyledBox
        sx={{
          position: "relative",
          top: -drawerBleeding,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          visibility: "visible",
          right: 0,
          left: 0,
          paddingX: 2,
          paddingY: 2,
          overflow: "auto",
          height: "100%",
        }}
      >
        <Puller />
        <IconButton
          sx={{ position: "absolute", right: 0, top: 0, zIndex: 100 }}
          aria-label="close"
          size="large"
          onClick={toggleBottomSheet(false)}
        >
          <Close fontSize="inherit" />
        </IconButton>
        <Box sx={{ marginBottom: "1.3rem" }}></Box>
        {children}
      </StyledBox>
    </SwipeableDrawer>
  );
};

export default BottomSheet;
