import { Dagontvangsten, PaymentMethods } from "../api/types";

import CashLogo from "../assets/payment-methods/Cash.png";
import BancontactLogo from "../assets/payment-methods/Bancontact.png";
import KredietkaartLogo from "../assets/payment-methods/Kredietkaart.png";
import PayconiqLogo from "../assets/payment-methods/Payconiq.png";
import MaaltijdchequesLogo from "../assets/payment-methods/Maaltijdcheques.png";
import EcochequesLogo from "../assets/payment-methods/Ecocheques.png";
import OverschrijvingLogo from "../assets/payment-methods/Overschrijving.png";
import WebshopLogo from "../assets/payment-methods/Webshop.png";

export const PaymentMethodsIcons: { [key: string]: string } = {
  Cash: CashLogo,
  Bancontact: BancontactLogo,
  Kredietkaart: KredietkaartLogo,
  Payconiq: PayconiqLogo,
  Maaltijdcheques: MaaltijdchequesLogo,
  Ecocheques: EcochequesLogo,
  Overschrijving: OverschrijvingLogo,
  Webshop: WebshopLogo,
};

export const getVatAmount = ({
  vatRates,
  vatRateId,
}: {
  vatRates: Dagontvangsten.VatDetailRateDto[];
  vatRateId: number;
}) => {
  const findAmountRate = vatRates.find(
    (item: { vatRateId: number }) => item.vatRateId === vatRateId
  );
  return findAmountRate ? findAmountRate.amountVatRate : 0;
};

export const getPaymentMethodName = ({
  paymentMethods,
  paymentMethodId,
}: {
  paymentMethods: PaymentMethods.GetPaymentMethodsForCompany.PaymentMethodDetail[];
  paymentMethodId: number | null;
}) => {
  const findPaymentName = paymentMethods.find(
    (item: { paymentMethodId: number }) =>
      item.paymentMethodId === paymentMethodId
  );
  return findPaymentName ? findPaymentName?.paymentMethodName : "";
};
