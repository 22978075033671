import React, { useState } from "react";
import { ListItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from "react-router-dom";

interface ListItemLinkProps {
  icon?: React.ReactElement;
  subMenuIcon?: React.ReactElement;
  primary: string;
  to: string;
  selectedLink: string;
  subMenu?: boolean;
  subMenuPrimary?: string;
  subMenuLink?: string;
  subMenuID?: string;
  onClickListItem?: () => void;
}

function ListItemLink({
  icon,
  primary,
  to,
  selectedLink,
  onClickListItem,
}: ListItemLinkProps) {
  const location = useLocation();
  const [nestedLinkOpen, setNestedLinkOpen] = useState(false);
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, "to">>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );
  const handleListItemClick = (listItem: string, subMenuToggle: boolean) => {
    if (onClickListItem) {
      onClickListItem();
    }
    selectedLink = listItem;
    subMenuToggle && setNestedLinkOpen(!nestedLinkOpen);
  };

  return (
    <>
      <ListItem
        key={selectedLink}
        button
        component={renderLink}
        selected={selectedLink === location.pathname}
        onClick={() => handleListItemClick(selectedLink, true)}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
      <Divider />
    </>
  );
}
export default ListItemLink;
