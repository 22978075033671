import React, { useContext, useState, useEffect, useCallback } from "react";
import { DailyRevenueSingleRow, initialValues } from "./DailyRevenue.config";
import styles from "./DailyRevenue.module.scss";
import {
  Fab,
  Paper,
  Box,
  useMediaQuery,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DisplayRevenueData from "../../components/DisplayRevenueData/DisplayRevenueData";
import { ProfileContext } from "../../providers/profile";
import { useDailyRevenue } from "../../providers/dailyRevenue";
import MobileDisplayRevenueData from "../../components/MobileDisplayRevenueData/MobileDisplayRevenueData";
import { format, startOfMonth, endOfMonth } from "date-fns";
import { dataCalculation } from "../../utils/dataCalculation";
import { yearDateFormat } from "../../utils/dateFormat";
import { useTranslation } from "react-i18next";
import DailyRevenueInput from "../../components/DailyRevenueInput/DailyRevenueInput";
import RestrictedDatePicker from "../../components/DatePicker/RestrictedDatePicker";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import { FileDownload } from "@mui/icons-material";
import DailyRevenueExport from "../../components/Export/DailyRevenueExport";
import { roundNumber } from "../../utils/currencyFormat";

export default function DailyRevenue() {
  const [collapse, setCollapse] = useState(false);
  const [dailyRevenueExportModalOpen, setDailyRevenueExportModalOpen] =
    useState(false);
  const {
    addDailyRevenue,
    updateDailyRevenue,
    dailyRevenueData,
    selectedMonthRevenueData,
    fetchDailyRevenueByDate,
    fetchCustomRangeDailyRevenue,
    closeMultipleRecords,
    closeMultipleEmptyRecords,
  } = useDailyRevenue();
  const { paymentMethods, vatRates, selectedCompanyID, openBackDrop } =
    useContext(ProfileContext);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { t: translate } = useTranslation();
  const desktopDisplay = dataCalculation({
    dataDisplay: selectedMonthRevenueData,
    paymentMethods,
    selectedDate,
  });
  const [chosenDate, setChosenDate] = useState(new Date());
  const isMobileOrTablet = useMediaQuery("(max-width:600px)");
  const drawerBleeding = 0;
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  useEffect(() => {
    const getCurrentMonth = new Date().getMonth();
    const getMonth = new Date(selectedDate).getMonth();
    openBackDrop(true);

    if (getMonth !== getCurrentMonth) {
      const startDate = startOfMonth(new Date(selectedDate));
      const endDate = endOfMonth(new Date(selectedDate));
      selectedCompanyID &&
        fetchCustomRangeDailyRevenue({
          companyId: selectedCompanyID,
          begin: yearDateFormat(startDate),
          end: yearDateFormat(endDate),
          showHistory: false,
        });
    }
    selectedCompanyID &&
      fetchDailyRevenueByDate({
        date: format(new Date(selectedDate), "yyyy-MM-dd"),
        companyId: selectedCompanyID,
      }).finally(() => openBackDrop(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedCompanyID]);

  const handleDatechange = useCallback(
    (newDate: Date) => {
      setChosenDate(newDate);
      setSelectedDate(newDate);
    },
    [setChosenDate]
  );

  const saveWholeDay = (
    values: {
      id: number | null;
      day: string;
      vatDetails: DailyRevenueSingleRow[];
      total: number;
      completed?: boolean;
    },
    completed: boolean,
    callbackFn?: (done: boolean) => void
  ) => {
    if (values.id === null) {
      selectedCompanyID &&
        addDailyRevenue({
          ...values,
          total: roundNumber(
            values.vatDetails.reduce(
              (accum, cur) => accum + roundNumber(cur.total, "2"),
              0
            ),
            "2"
          ),
          completed: completed,
          companyId: selectedCompanyID,
          day: format(new Date(values.day), "yyyy-MM-dd"),
        }).finally(() => callbackFn && callbackFn(false));
    } else {
      updateDailyRevenue(
        {
          id: values.id,
          total: roundNumber(
            values.vatDetails.reduce(
              (accum, cur) => accum + roundNumber(cur.total, "2"),
              0
            ),
            "2"
          ),
          vatDetails: values.vatDetails,
          completed: completed as boolean,
        },
        values.day
      ).finally(() => callbackFn && callbackFn(false));
    }
  };

  const lockIds = (extractId: number[]) => {
    const payload = {
      vatDetails: extractId,
    };
    const selectedDateByUser = format(new Date(selectedDate), "yyyy-MM-dd");
    closeMultipleRecords(payload, selectedDateByUser);
  };

  const setFullDayLock = (lockDate: Date) => {
    const selectedDateByUser = format(new Date(selectedDate), "yyyy-MM-dd");
    const extractDate = lockDate.getDate();
    const extractMonth = lockDate.getMonth() + 1; // JS indexes months starting from 0
    const extractYear = lockDate.getFullYear();
    const dayToComplete = `${extractYear}-${extractMonth}-${extractDate}`;
    closeMultipleEmptyRecords({
      dayToComplete,
      userSelectedDate: selectedDateByUser,
    });
  };

  const toggleBottomSheet =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsBottomSheetOpen(open);
      setCollapse(!collapse);
    };

  return (
    <>
      <Box className={styles.heading}>
        <h2>{translate("dailyRevenue")} </h2>
        <div className={styles.layout}>
          <Fab
            style={{ alignSelf: "center" }}
            color={collapse ? undefined : "primary"}
            variant={collapse ? undefined : "extended"}
            aria-label="Add Daily Revenue"
            onClick={() => {
              if (isMobileOrTablet) {
                setIsBottomSheetOpen(!collapse);
              }
              setCollapse(!collapse);
            }}
          >
            {collapse ? (
              <RemoveIcon />
            ) : (
              <>
                {translate("fillIn")} <AddIcon sx={{ mr: 1 }} />
              </>
            )}
          </Fab>
          <RestrictedDatePicker
            value={chosenDate}
            onChange={handleDatechange}
          />
          <Tooltip arrow title={translate("export") ?? ""}>
            <IconButton
              aria-label="export"
              size="large"
              onClick={() => setDailyRevenueExportModalOpen(true)}
            >
              <FileDownload fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>
      </Box>

      <div className={styles.dailyRevenue}>
        {!isMobileOrTablet && collapse && (
          <Paper className={styles.inputContainer}>
            <DailyRevenueInput
              vatRates={vatRates}
              paymentMethods={paymentMethods}
              onSave={saveWholeDay}
              values={initialValues(dailyRevenueData, vatRates, selectedDate)}
            />
          </Paper>
        )}
        <br />
        <Paper className={styles.mobileDisplayWrapper}>
          <div className={styles.dataDisplay}>
            <DisplayRevenueData
              lockIds={lockIds}
              dataDisplay={desktopDisplay}
              selectedDate={new Date(selectedDate)}
              setFullDayLock={setFullDayLock}
            />
          </div>
          <div className={styles.mobileDisplay}>
            <MobileDisplayRevenueData mobileDisplay={desktopDisplay} />
          </div>
        </Paper>

        {isMobileOrTablet && (
          <BottomSheet
            isBottomSheetOpen={isBottomSheetOpen}
            toggleBottomSheet={toggleBottomSheet}
            drawerBleeding={drawerBleeding}
          >
            <>
              <Typography sx={{ textAlign: "center" }} color="primary" mb={1}>
                {chosenDate?.toLocaleDateString()}
              </Typography>
              <DailyRevenueInput
                vatRates={vatRates}
                paymentMethods={paymentMethods}
                onSave={saveWholeDay}
                values={initialValues(dailyRevenueData, vatRates, selectedDate)}
              />
            </>
          </BottomSheet>
        )}
      </div>

      <Dialog
        onClose={() => setDailyRevenueExportModalOpen(false)}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="export-daily-revenue-dialog"
        open={dailyRevenueExportModalOpen}
      >
        <DialogTitle
          id="export-daily-revenue-dialog"
          sx={{ display: "flex", alignContent: "center", alignItems: "center" }}
        >
          <FileDownload sx={{ mr: 1 }} />
          <Typography variant="h5" component="h5">
            {translate("export")}
          </Typography>
        </DialogTitle>

        <div style={{ padding: "15px" }}>
          <DailyRevenueExport initialDate={selectedDate} />
        </div>
      </Dialog>
    </>
  );
}
