import React, { createContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

type IToastContextType = {
  toastHandler: ({
    toastVisible,
    toastMessage,
    toastType,
  }: IToastHandlerType) => void;
};

export const ToastContext = createContext({} as IToastContextType);

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type ICustomizedSnackbars = {
  children: any;
};
export type IToastHandlerType = {
  toastVisible: boolean;
  toastMessage: string;
  toastType: "success" | "info" | "warning" | "error" | undefined;
};

export const ToastProvider = ({ children }: ICustomizedSnackbars) => {
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [type, setType] =
    useState<"success" | "info" | "warning" | "error" | undefined>(undefined);

  function toastHandler({
    toastVisible,
    toastMessage,
    toastType,
  }: IToastHandlerType) {
    setToastVisible(toastVisible);
    setToastMessage(toastMessage);
    setType(toastType);
  }

  return (
    <ToastContext.Provider
      value={{
        toastHandler,
      }}
    >
      {children}
      <Snackbar
        open={toastVisible}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={() => setToastVisible(false)}
      >
        <Alert onClose={() => setToastVisible(false)} severity={type}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
