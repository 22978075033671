import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../providers/users";
import styles from "./CompanyManage.module.scss";
import { useNavigate, useParams } from "react-router";
import { useCompany } from "../../providers/company";
import UserSearch from "../../components/UserSearch/UserSearch";
import { Users } from "../../api/types";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import { ProfileContext } from "../../providers/profile";
import {
  Avatar,
  Button,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

export default function CompanyManage() {
  const params = useParams<any>();
  const navigate = useNavigate();
  const { company, getCompany, addUserToCompany, deleteUserFromCompany } =
    useCompany();
  const { companyUsers, getUsersByCompany } = useUsers();
  const [userToAdd, setUserToAdd] = useState<Users.Dto.UserDto | null>(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] =
    useState<Users.Dto.UserDto | null>(null);

  const { openBackDrop } = useContext(ProfileContext);
  const [addUserLoading, setAddUserLoading] = useState(false);

  const { t: translate } = useTranslation();

  const pageSize = 20;
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    console.log({ company });
    if (company) {
      loadUsersByCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, company]);

  const loadUsersByCompany = () => {
    openBackDrop(true);
    getUsersByCompany({
      companyId: company!.company.id,
      pageNumber: page,
      pageSize: pageSize,
    }).finally(() => openBackDrop(false));
  };

  useEffect(() => {
    if (!params || !params.companyId) {
      navigate("/company", { replace: true });
      return;
    }

    openBackDrop(true);
    getCompany(params.companyId)
      .then((company) => {})
      .catch(() => {
        openBackDrop(false);
        navigate("/company", { replace: true });
      })
      .finally(() => openBackDrop(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.companyId]);

  const handleAddUserToCompanyClick = () => {
    if (userToAdd) {
      setAddUserLoading(true);
      addUserToCompany({
        companyId: company?.company.id!,
        userId: userToAdd.id,
      })
        .then(() => {
          setAddUserLoading(false);
          page !== 1 ? setPage(1) : loadUsersByCompany();
        })
        .catch(() => setAddUserLoading(false));
    }
  };

  const handleDeleteUserClick = (user: any) => {
    setUserToDelete(user);
    setConfirmationModalOpen(true);
  };

  const handleDeleteUserClose = () => {
    setUserToDelete(null);
    setConfirmationModalOpen(false);
  };

  const handleDeleteUser = () => {
    if (!userToDelete || !company) {
      setConfirmationModalOpen(false);
    } else {
      openBackDrop(true);
      deleteUserFromCompany({
        companyId: company.company.id,
        userId: userToDelete.id,
      })
        .then(() => {
          setConfirmationModalOpen(false);
          page !== 1 ? setPage(1) : loadUsersByCompany();
        })
        .finally(() => openBackDrop(false));
    }
  };

  const hasItems =
    companyUsers?.items?.length !== undefined &&
    companyUsers?.items?.length > 0;

  return (
    <div className={styles.companyManage}>
      <div className={styles.heading}>
        <h2>
          {translate("manageHeading")} {company?.company?.name}
        </h2>

        <div className={styles.userSearch}>
          <UserSearch onChange={(value) => setUserToAdd(value)} />
          <Button
            onClick={handleAddUserToCompanyClick}
            type="button"
            variant="contained"
            color="primary"
            className={styles.addUserButton}
            disabled={addUserLoading}
          >
            {addUserLoading && (
              <CircularProgress
                size={15}
                color="primary"
                className={styles.loadingSpinner}
              />
            )}
            {translate("addUserToCompany")}
          </Button>
        </div>
      </div>
      <Paper className={styles.tableContainer}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{translate("email")}</TableCell>
                <TableCell>{translate("name")}</TableCell>
                <TableCell>{translate("emailVerified")}</TableCell>
                <TableCell>{translate("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyUsers?.items.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Avatar alt="User Avatar" src={user.picture} />
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>
                    {user.emailVerified ? translate("yes") : translate("no")}
                  </TableCell>
                  <TableCell>
                    <Delete
                      onClick={() => handleDeleteUserClick(user)}
                      style={{ cursor: "pointer", color: "#f50057" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {hasItems && (
            <Pagination
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className={styles.tablePagination}
              count={companyUsers?.totalPages}
              page={page}
              onChange={handleChange}
            />
          )}
        </TableContainer>
      </Paper>
      <ConfirmationDialog
        open={confirmationModalOpen}
        onClose={handleDeleteUserClose}
        title={translate("deleteUserFromCompanyConfirmation")}
        onCancel={handleDeleteUserClose}
        onConfirm={handleDeleteUser}
      />
    </div>
  );
}
