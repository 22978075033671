import React, { useContext } from "react";
import DashboardPage from "./pages/dashboard/DashboardPage";
import { Route, Routes, Navigate } from "react-router";
import DetailPage from "./pages/detail/DetailPage";
import styles from "./AuthenticatedApp.module.scss";
import CssBaseline from "@mui/material/CssBaseline";
import { Backdrop, CircularProgress, Toolbar } from "@mui/material";
import CompanyDetails from "./pages/companyDetails/CompanyDetails";
import { ProfileProvider, ProfileContext } from "./providers/profile";
import { ToastProvider } from "./providers/snackbar";
import DailyRevenue from "./pages/dailyRevenue/DailyRevenue";
import CashRegister from "./pages/cashRegister/CashRegister";
import CodaUtils from "./pages/codaUtils/CodaUtils";
import Layout from "./components/Layout/Layout";
import companyLogo from "./assets/Kmodo_full_cropped.png";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Users from "./pages/users/Users";
import { ACCOUNTANT_ADMIN } from "./api/constants";
import CompanyManage from "./pages/companyManage/CompanyManage";

function AuthenticatedAppRouter() {
  const { companyDetailsExist, companyDetailsLoading } =
    useContext(ProfileContext) || {};

  return (
    <Routes>
      <Route
        path="/"
        element={
          companyDetailsLoading ? (
            <Backdrop
              style={{ zIndex: 1202, color: "black", backgroundColor: "white" }}
              open={true}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="company-logo" src={companyLogo} width="20%" />
                <br />
                <CircularProgress color="inherit" />
              </div>
            </Backdrop>
          ) : companyDetailsExist ? (
            <Navigate to="/daily-revenue" />
          ) : (
            <DashboardPage />
          )
        }
      />
      <Route path="/detail" element={<DetailPage />} />
      <Route path="/daily-revenue" element={<DailyRevenue />} />
      <Route path="/cash-register" element={<CashRegister />} />
      <Route
        path="/coda"
        element={
          <ProtectedRoute role={[ACCOUNTANT_ADMIN]}>
            <CodaUtils />
          </ProtectedRoute>
        }
      />
      <Route path="/company" element={<CompanyDetails />} />
      <Route path="/company/:companyId" element={<CompanyManage />} />
      <Route
        path="/users"
        element={
          <ProtectedRoute role={[ACCOUNTANT_ADMIN]}>
            <Users />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

function AuthenticatedApp() {
  return (
    <div className={styles.sideNavigation}>
      <div className={styles.root}>
        <CssBaseline />
        <ToastProvider>
          <ProfileProvider>
            <Layout />
            <main className={styles.content}>
              <Toolbar />
              <AuthenticatedAppRouter />
            </main>
          </ProfileProvider>
        </ToastProvider>
      </div>
    </div>
  );
}
export default AuthenticatedApp;
