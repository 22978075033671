import * as Yup from "yup";

export type DailyRevenueSingleRow = {
  vatRates: VatRateData[];
  paymentMethodId: number;
  description: string;
  total: number;
  hash?: string;
  completed?: boolean;
  id: null | number;
};
type VatRateData = {
  vatRateId: number;
  amountVatRate: number;
};
export type DailyRevenueFormValues = {
  id: null | number;
  day: string;
  vatDetails: DailyRevenueSingleRow[];
  total: number;
};
export const validationSchema = Yup.object({
  id: Yup.number().nullable(true),
  day: Yup.string().required("Date amount is required"),
  total: Yup.number().required("Total amount is required"),
  vatDetails: Yup.array().of(
    Yup.object().shape({
      vatRates: Yup.array().of(
        Yup.object().shape({
          vatRateId: Yup.number(),
          amountVatRate: Yup.number(),
        })
      ),
      paymentMethodId: Yup.number().nullable(true),
      description: Yup.string(),
      total: Yup.number(),
      hash: Yup.string(),
      completed: Yup.boolean(),
    })
  ),
});

const toValuesNumber = (value?: number | null) =>
  typeof value === "number" ? value : 0;

// @ TODO any type
export const initialValues: any = (
  dailyRevenueData: any,
  vatRate: any,
  newDate: Date
) => {
  if (Object.keys(dailyRevenueData).length > 0) {
    const clientVatRate = (index: number) => {
      const clientVatRateArray: { vatRateId: any; amountVatRate: number }[] =
        [];
      // @ TODO any type
      vatRate.forEach((rate: any) => {
        const amountVatRate = dailyRevenueData.vatDetails[index].vatRates.find(
          (item: { vatRateId: any }) => item.vatRateId === rate.vatRateId
        );
        amountVatRate &&
          clientVatRateArray.push({
            vatRateId: rate.vatRateId,
            amountVatRate: amountVatRate.amountVatRate,
          });
      });
      return clientVatRateArray;
    };

    const vatDetails: DailyRevenueSingleRow[] = [];
    dailyRevenueData.vatDetails.forEach((detail: any, index: number) => {
      vatDetails.push({
        vatRates: clientVatRate(index),
        description: detail.description,
        paymentMethodId: detail.paymentMethodId,
        total: toValuesNumber(null),
        hash: detail.hash,
        completed: detail.completed,
        id: detail.id,
      });
    });

    const initialData = {
      id: dailyRevenueData.id,
      day: dailyRevenueData.day,
      total: dailyRevenueData.total,
      vatDetails: vatDetails,
    };
    return initialData;
  } else {
    // @ TODO any type
    const clientVatRate: { vatRateId: any; amountVatRate: number }[] =
      vatRate.map((rate: any) => ({
        vatRateId: rate.vatRateId,
        amountVatRate: 0,
      }));

    const initialData = {
      id: null,
      day: newDate,
      total: 0,
      vatDetails: [
        {
          vatRates: clientVatRate,
          description: "",
          paymentMethodId: "",
          total: toValuesNumber(null),
        },
      ],
    };
    return initialData;
  }
};
