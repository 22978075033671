import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useContext } from "react";
import { Navigate } from "react-router";
import { RouteProps } from "react-router";
import { ProfileContext } from "../../providers/profile";

export type ProtectedRouteProps = {
  role: string[];
} & RouteProps;

const ProtectedRoute = ({
  role,
  children,
}: ProtectedRouteProps): JSX.Element => {
  const { isAuthenticated } = useAuth0();
  const { profile } = useContext(ProfileContext);

  const hasRequiredRole = (): boolean => {
    for (let r of role) {
      if (profile?.role?.indexOf(r) !== -1) {
        return true;
      }
    }
    return false;
  };

  if (isAuthenticated && (role.length === 0 || hasRequiredRole())) {
    return children as JSX.Element;
  } else {
    return <Navigate to={{ pathname: "/" }} replace />;
  }
};

export default ProtectedRoute;
