import React from "react";
import { Field } from "formik";
import FormikTextField from "../forms/FormikTextField";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

const UserFields = () => {
  const { t: translate } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Field
          name="email"
          type="email"
          component={FormikTextField}
          label={translate("email")}
        />
        <Field
          name="firstName"
          type="text"
          component={FormikTextField}
          label={translate("firstName")}
        />
        <Field
          name="lastName"
          type="text"
          component={FormikTextField}
          label={translate("lastName")}
        />
        <Field
          name="password"
          type="password"
          component={FormikTextField}
          label={translate("password")}
        />
      </Grid>
    </Grid>
  );
};

export default UserFields;
