import { useAxios } from "../utils/axiosConfig";
import { useContext } from "react";
import { getServerErrorMessages } from "../utils/getServerErrorsMessages";
import { ToastContext } from "./snackbar";
import fileDownload from "js-file-download";

export const useCodaUtilsAPI = () => {
  const { axiosHandler } = useAxios();
  const { toastHandler } = useContext(ToastContext);

  const convertCsvToCoda = async (openingBalance: Number, csv: File) => {
    console.log(openingBalance);
    console.log(csv);

    var formData = new FormData();
    formData.append("csvFile", csv);
    await axiosHandler()
      .post(`/tools/csv-to-coda?openingBalance=${openingBalance}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      })
      .then((response) => {
        console.log(response);
        const nameWithoutExtension = csv.name.replace(/\.[^/.]+$/, "");
        fileDownload(response.data, `${nameWithoutExtension}.coda`);
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  return { convertCsvToCoda };
};
