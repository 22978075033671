import { useContext, useState } from "react";
import { Users } from "../api/types";
import { useAxios } from "../utils/axiosConfig";
import { ToastContext } from "./snackbar";
import { getServerErrorMessages } from "../utils/getServerErrorsMessages";

export const useUsers = () => {
  const [createdUsers, setCreatedUsers] =
    useState<Users.GetUsersCreatedByCurrentUser.Response>();
  const [companyUsers, setCompanyUsers] =
    useState<Users.GetUsersByCompany.Response>();
  const [publicAdminUsers, setPublicAdminUsers] =
    useState<Users.GetPublicAdminUsers.WithLinkDetailsResponse>();
  const [adminUsers, setAdminUsers] =
    useState<Users.GetPublicAdminUsers.Response>();
  const { toastHandler } = useContext(ToastContext);
  const { axiosHandler } = useAxios();

  const getCreatedUsers = async (
    payload: Users.GetUsersCreatedByCurrentUser.Request
  ) => {
    await axiosHandler()
      .get(
        `/users/created?query=${payload.query}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
      )
      .then((response) => {
        if (response.status === 200) {
          setCreatedUsers(response.data);
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  const createUser = async (payload: Users.CreateUser.Request) => {
    await axiosHandler()
      .post("/users", { ...payload })
      .then((response) => {})
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  const getUsersByCompany = async (
    payload: Users.GetUsersByCompany.Request
  ) => {
    await axiosHandler()
      .get(
        `/users/company?companyId=${payload.companyId}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
      )
      .then((response) => {
        if (response.status === 200) {
          setCompanyUsers(response.data);
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  const getPublicAdminUsers = async (
    payload: Users.GetPublicAdminUsers.WithLinkDetailsRequest
  ) => {
    await axiosHandler()
      .get(
        `/users/public-admins?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
      )
      .then((response) => {
        if (response.status === 200) {
          setPublicAdminUsers(response.data);
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  const getAdminUsers = async (payload: Users.GetPublicAdminUsers.Request) => {
    await axiosHandler()
      .get(
        `/users/other-admins?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
      )
      .then((response) => {
        if (response.status === 200) {
          setAdminUsers(response.data);
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  const deleteUser = async (userId: string) => {
    await axiosHandler()
      .delete(`/users/${userId}`)
      .then((response) => {})
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  const updatePublicAdminLinkStatus = async (
    userId: string,
    linkRequestSent: boolean,
    linked: boolean
  ) => {
    if (!userId || !publicAdminUsers) {
      return;
    }

    const updatedList = publicAdminUsers.items.map((user) =>
      user.id === userId
        ? { ...user, linkRequestSent: linkRequestSent, linked: linked }
        : user
    );

    setPublicAdminUsers({ ...publicAdminUsers, items: updatedList });
  };

  return {
    createdUsers,
    getCreatedUsers,
    createUser,
    companyUsers,
    getUsersByCompany,
    publicAdminUsers,
    getPublicAdminUsers,
    adminUsers,
    getAdminUsers,
    deleteUser,
    updatePublicAdminLinkStatus,
  };
};
