import * as Yup from "yup";
import i18n from "../../locale/i18n";

export type UserCreateFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};
export const validationSchema = () =>
  Yup.object({
    email: Yup.string()
      .required(i18n.t("emailRequired"))
      .email(i18n.t("notValidEmail")),
    firstName: Yup.string().required(i18n.t("firstNameRequired")),
    lastName: Yup.string().required(i18n.t("lastNameRequired")),
    password: Yup.string()
      .required(i18n.t("passwordRequired"))
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        i18n.t("passwordFormat")
      ),
  });

export const initialValues: UserCreateFormValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
};
