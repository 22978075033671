import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik, Form, FieldArray, getIn } from "formik";
import { initialValues, validationSchema } from "./CashRegister.config";
import styles from "./CashRegister.module.scss";
import {
  Paper,
  Grid,
  Pagination,
  TextField,
  Divider,
  Button,
  useMediaQuery,
  CircularProgress,
  Tooltip,
  Zoom,
  Typography,
} from "@mui/material";
import { Field } from "formik";
import FormikDateField from "../../components/forms/FormikDateField";
import { ProfileContext } from "../../providers/profile";
import FormikMoneyField from "../../components/forms/FormikMoneyField";
import { useCashRegister } from "../../providers/cashRegister";
import { useTranslation } from "react-i18next";
import FormikTextField from "../../components/forms/FormikTextField";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { CashRegister } from "../../api/types";
import { yearDateFormat } from "../../utils/dateFormat";
import { Box } from "@mui/system";
import { Add, Clear } from "@mui/icons-material";
import { currencyFormat } from "../../utils/currencyFormat";
import coinsImage from "../../assets/icons/coins.png";

export default function CashRegisterPage() {
  const {
    addCashRegister,
    getCashRegisterByMonth,
    pageSize,
    pageIndex,
    setPageIndex,
    deleteCashRegister,
    updateCashRegister,
    cashRegisterData,
  } = useCashRegister();
  const { selectedCompanyID } = useContext(ProfileContext);
  const { t: translate } = useTranslation();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );
  const bottomOfScreen = useRef<null | HTMLDivElement>(null);
  const isMobileOrTablet = useMediaQuery("(max-width:600px)");
  const [deletedCashRegisterIds, setDeletedCashRegisterIds] = useState<
    number[]
  >([]);

  useEffect(() => {
    fetchCashRegisterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCashRegisterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    fetchCashRegisterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedCompanyID]);

  const fetchCashRegisterData = () => {
    const month = selectedDate?.getMonth();
    const year = selectedDate?.getFullYear();
    selectedCompanyID &&
      month !== undefined &&
      year &&
      getCashRegisterByMonth({
        companyId: selectedCompanyID,
        month: month,
        year: year,
        pageNumber: pageIndex,
        pageSize: pageSize,
      });
  };

  const handleDateChange = (date: any /*Date | null*/) => {
    setSelectedDate(date);
  };

  const saveCashRegister = (
    details: CashRegister.CashRegisterRecordDto,
    fieldIndex: number
  ) => {
    const { id } = details;
    const month = selectedDate?.getMonth();
    const year = selectedDate?.getFullYear();

    if (id) {
      if (selectedCompanyID && year && month !== undefined) {
        return updateCashRegister(
          {
            ...details,
            day: yearDateFormat(new Date(details.day)),
          },
          selectedCompanyID,
          month,
          year
        );
      }
    } else {
      if (selectedCompanyID && year && month !== undefined) {
        return addCashRegister(
          {
            ...details,
            companyId: selectedCompanyID,
            day: yearDateFormat(new Date(details.day)),
          },
          selectedCompanyID,
          month,
          year
        );
      }
    }
  };

  const saveCashRegisterForWholeMonth = async (
    cashRegisterValues: CashRegister.CashRegisterRecordDto[]
  ) => {
    for await (const deleteCashRegisterId of deletedCashRegisterIds) {
      await deleteCashRegisterEntry(deleteCashRegisterId);
    }

    for await (const [key, cashRegisterDto] of cashRegisterValues?.entries()) {
      await saveCashRegister(cashRegisterDto, key);
    }

    setDeletedCashRegisterIds([]);
  };

  const deleteCashRegisterEntry = (cashID: number) => {
    const month = selectedDate?.getMonth();
    const year = selectedDate?.getFullYear();
    if (selectedCompanyID && month !== undefined && year) {
      return deleteCashRegister({
        cashID,
        companyId: selectedCompanyID,
        month: month,
        year,
      });
    }
  };

  const onPaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
  };

  return (
    <>
      <Box className={styles.heading}>
        <h2>{translate("cashRegister")} </h2>
        <DesktopDatePicker
          openTo="month"
          views={["month", "year"]}
          label={translate("month")}
          inputFormat={"MMMM yyyy"}
          renderInput={(params) => <TextField {...params} />}
          value={selectedDate}
          onChange={handleDateChange}
          disableFuture={true}
        />
      </Box>
      <div className={styles.cashRegister}>
        <Paper className={styles.inputContainer}>
          <Grid container direction="row" alignItems="center">
            <Grid
              item
              sm={2}
              md={1.2}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(29,30,28,.07)",
                  borderRadius: "50%",
                }}
                width="70px"
                height="70px"
              >
                <img
                  alt="coins-icon"
                  src={coinsImage}
                  width="60px"
                  height="60px"
                />
              </Box>
            </Grid>

            <Grid item xs={6} sm={5} md={1.8}>
              <Typography>{translate("openingBalance")}</Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                €
                {cashRegisterData && cashRegisterData.openingBalance
                  ? currencyFormat(cashRegisterData.openingBalance)
                  : 0}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={5} md={1.8}>
              <Typography> {translate("closingBalance")}</Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                €
                {cashRegisterData && cashRegisterData.closingBalance
                  ? currencyFormat(cashRegisterData.closingBalance)
                  : 0}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={styles.inputContainer}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues(cashRegisterData)}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              console.log(values);
              await saveCashRegisterForWholeMonth(values.items);
            }}
          >
            {({ values, isSubmitting, errors, touched }) => {
              const { items, totalPages } = values;
              return (
                <Form>
                  <FieldArray
                    name="items"
                    render={(arrayHelpers) => (
                      <>
                        {items &&
                          items.map((details: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <Grid container item spacing={1} sx={{ my: 0 }}>
                                  <Grid item xs={12} sm={6} md={2.5}>
                                    <Field
                                      name={`items.${index}.day`}
                                      type="text"
                                      component={FormikDateField}
                                      label={translate("date")}
                                      error={
                                        getIn(touched, `items.${index}.day`) &&
                                        Boolean(
                                          getIn(errors, `items.${index}.day`)
                                        )
                                      }
                                      disabled={details.dagontvangstId}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={2.5}>
                                    <Field
                                      name={`items.${index}.relation`}
                                      type="text"
                                      component={FormikTextField}
                                      label={translate("relation")}
                                      error={
                                        getIn(
                                          touched,
                                          `items.${index}.relation`
                                        ) &&
                                        Boolean(
                                          getIn(
                                            errors,
                                            `items.${index}.relation`
                                          )
                                        )
                                      }
                                      disabled={details.dagontvangstId}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={3}>
                                    <Field
                                      name={`items.${index}.description`}
                                      type="text"
                                      component={FormikTextField}
                                      label={translate("description")}
                                      error={
                                        getIn(
                                          touched,
                                          `items.${index}.description`
                                        ) &&
                                        Boolean(
                                          getIn(
                                            errors,
                                            `items.${index}.description`
                                          )
                                        )
                                      }
                                      disabled={details.dagontvangstId}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>

                                  <Grid item xs={8} sm={4} md={2}>
                                    <Field
                                      name={`items.${index}.amount`}
                                      type="text"
                                      component={FormikMoneyField}
                                      label={translate("amount")}
                                      error={
                                        getIn(
                                          touched,
                                          `items.${index}.amount`
                                        ) &&
                                        Boolean(
                                          getIn(errors, `items.${index}.amount`)
                                        )
                                      }
                                      disabled={details.dagontvangstId}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={4}
                                    sm={2}
                                    md={0.8}
                                    container
                                    sx={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexWrap: "nowrap",
                                      flexDirection: "column",
                                      mb: "1rem",
                                    }}
                                  >
                                    <Tooltip
                                      arrow
                                      TransitionComponent={Zoom}
                                      title={translate("delete") ?? "Verwijder"}
                                    >
                                      <Grid item xs="auto">
                                        <Button
                                          aria-label="Delete Row"
                                          variant="outlined"
                                          sx={{ padding: "5px" }}
                                          onClick={() => {
                                            arrayHelpers.remove(index);

                                            if (details.id) {
                                              setDeletedCashRegisterIds([
                                                ...deletedCashRegisterIds,
                                                details.id,
                                              ]);
                                            }
                                          }}
                                          size="small"
                                          style={{ minWidth: 0 }}
                                          disabled={
                                            items.length <= 1 ||
                                            details.dagontvangstId
                                          }
                                        >
                                          <Clear />
                                        </Button>
                                      </Grid>
                                    </Tooltip>
                                  </Grid>
                                </Grid>

                                <Divider
                                  sx={{
                                    display: "block",
                                    mb: 1,
                                  }}
                                />
                              </React.Fragment>
                            );
                          })}

                        <div
                          id="bottomOfList"
                          ref={
                            bottomOfScreen as React.RefObject<HTMLDivElement>
                          }
                        />

                        <Grid
                          container
                          spacing={1}
                          sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                            mb: 1,
                            mt: 1,
                            pb: isMobileOrTablet ? 0 : 0,
                            position: isMobileOrTablet ? "sticky" : "relative",
                            bottom: isMobileOrTablet ? 0 : "inherit",
                            background: isMobileOrTablet ? "#fff" : "inherit",
                            zIndex: 100,
                          }}
                        >
                          <Grid xs={12} sm={9.5} md={9.5} item>
                            <Button
                              sx={{ marginRight: 1 }}
                              aria-label="Add Row"
                              variant="outlined"
                              color="primary"
                              disabled={isSubmitting}
                              size={"medium"}
                              startIcon={<Add />}
                              onClick={() => {
                                arrayHelpers.push({
                                  id: undefined,
                                  day: new Date(),
                                  amount: 0,
                                  relation: undefined,
                                  description: undefined,
                                });

                                if (isMobileOrTablet) {
                                  setTimeout(() => {
                                    bottomOfScreen.current?.scrollIntoView({
                                      behavior: "smooth",
                                    });
                                  }, 100);
                                }
                              }}
                            >
                              {translate("addRow")}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                              size={"medium"}
                            >
                              {isSubmitting && (
                                <CircularProgress
                                  size={15}
                                  color="secondary"
                                  className={styles.loadingSpinner}
                                />
                              )}
                              {translate("saveWholeDay")}
                            </Button>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={2.5}
                            md={2.5}
                            sx={{
                              display: "flex",
                              alignItems: {
                                xs: "center",
                              },
                              justifyContent: {
                                xs: "center",
                              },
                            }}
                          >
                            {cashRegisterData && totalPages > 1 && (
                              <Pagination
                                count={totalPages}
                                variant="outlined"
                                shape="rounded"
                                color="primary"
                                onChange={onPaginationChange}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </div>
    </>
  );
}
