import { CommonError } from "../api/commonError";
import { IToastHandlerType } from "../providers/snackbar";

export const getServerErrorMessages = (error: any): IToastHandlerType[] => {
  const errors: IToastHandlerType[] = [];
  if (error.request.status === 0) {
    errors.push({
      toastVisible: true,
      toastMessage: "Server is not responding",
      toastType: "error",
    });

    return errors;
  }

  if (error.response.data) {
    const commonError = error.response.data as CommonError;
    errors.push({
      toastVisible: true,
      toastMessage: commonError.description,
      toastType: "error",
    });

    for (let e of commonError.errors) {
      errors.push({
        toastVisible: true,
        toastMessage: e.message,
        toastType: "error",
      });
    }
  } else {
    errors.push({
      toastVisible: true,
      toastMessage: `${error.message}`,
      toastType: "error",
    });
  }

  return errors;
};
