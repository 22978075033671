export const currencyFormat = (amount: number) => {
  return new Intl.NumberFormat("de-DE", {
    currency: "EUR",
    minimumFractionDigits: 2,
  }).format(amount);
};

export const roundNumber = (number: number, decimals: string) => {
  var newnumber = new Number(number + "").toFixed(parseInt(decimals));
  return parseFloat(newnumber);
};
