import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../providers/users";
import { Form, Formik } from "formik";
import { initialValues, validationSchema } from "./Users.config";
import UserFields from "../../components/UserFields/UserFields";
import styles from "./Users.module.scss";
import { useEffect, useContext } from "react";
import { Users as UserType } from "../../api/types";
import { ProfileContext } from "../../providers/profile";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, GroupAdd } from "@mui/icons-material";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";

export default function Users() {
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] =
    useState<UserType.Dto.UserDto | null>(null);

  const { t: translate } = useTranslation();

  const { createdUsers, getCreatedUsers, createUser, deleteUser } = useUsers();
  const { openBackDrop } = useContext(ProfileContext);

  const handleCreateUserModalClose = () => {
    setCreateUserModalOpen(false);
  };

  const pageSize = 20;
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const [addLoading, setAddLoading] = useState(false);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadUsers = () => {
    openBackDrop(true);
    getCreatedUsers({
      query: "",
      pageNumber: page,
      pageSize: pageSize,
    }).finally(() => openBackDrop(false));
  };

  const handleDeleteUserClick = (user: any) => {
    setUserToDelete(user);
    setConfirmationModalOpen(true);
  };

  const handleDeleteUserClose = () => {
    setUserToDelete(null);
    setConfirmationModalOpen(false);
  };

  const handleDeleteUser = () => {
    if (!userToDelete) {
      setConfirmationModalOpen(false);
    } else {
      openBackDrop(true);
      deleteUser(userToDelete.id)
        .then(() => {
          setConfirmationModalOpen(false);
          page !== 1 ? setPage(1) : loadUsers();
        })
        .finally(() => openBackDrop(false));
    }
  };

  const hasItems =
    createdUsers?.items?.length !== undefined &&
    createdUsers?.items?.length > 0;

  return (
    <div className={styles.users}>
      <div className={styles.heading}>
        <h2>{translate("manageUsersHeading")}</h2>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size={"medium"}
          onClick={() => setCreateUserModalOpen(true)}
        >
          <GroupAdd sx={{ mr: 1 }} />
          {translate("createUserHeading")}
        </Button>
      </div>
      <Paper className={styles.tableContainer}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{translate("email")}</TableCell>
                <TableCell>{translate("name")}</TableCell>
                <TableCell>{translate("emailVerified")}</TableCell>
                <TableCell>{translate("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {createdUsers?.items.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Avatar alt="User Avatar" src={user.picture} />
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>
                    {user.emailVerified ? translate("yes") : translate("no")}
                  </TableCell>
                  <TableCell>
                    <Delete
                      onClick={() => handleDeleteUserClick(user)}
                      style={{ cursor: "pointer", color: "#f50057" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {hasItems && (
            <Pagination
              showFirstButton
              showLastButton
              variant="outlined"
              shape="rounded"
              className={styles.tablePagination}
              count={createdUsers?.totalPages}
              page={page}
              onChange={handleChange}
            />
          )}
        </TableContainer>
      </Paper>
      <Dialog
        onClose={handleCreateUserModalClose}
        aria-labelledby="simple-dialog-title"
        open={createUserModalOpen}
      >
        <DialogTitle id="simple-dialog-title">
          {translate("createUserHeading")}
        </DialogTitle>

        <div style={{ padding: "15px" }}>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              setAddLoading(true);
              createUser(values)
                .then(() => {
                  resetForm({});
                  setCreateUserModalOpen(false);
                  page !== 1 ? setPage(1) : loadUsers();
                })
                .finally(() => setAddLoading(false));
            }}
          >
            <Form>
              <UserFields />
              <Box display="flex" justifyContent="flex-end">
                <Button
                  disabled={addLoading}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {addLoading && (
                    <CircularProgress
                      size={15}
                      color="primary"
                      className={styles.loadingSpinner}
                    />
                  )}
                  {translate("save")}
                </Button>
              </Box>
            </Form>
          </Formik>
        </div>
      </Dialog>
      <ConfirmationDialog
        open={confirmationModalOpen}
        onClose={handleDeleteUserClose}
        title={translate("deleteUserConfirmation")}
        onCancel={handleDeleteUserClose}
        onConfirm={handleDeleteUser}
      />
    </div>
  );
}
