import React from "react";
import { Field } from "formik";
import { Grid } from "@mui/material";
import FormikTextField from "../forms/FormikTextField";
import { useTranslation } from "react-i18next";

const CompanyFields = () => {
  const { t: translate } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Field
          name="name"
          type="text"
          component={FormikTextField}
          label={translate("name")}
        />
        <Field
          name="vAT"
          type="text"
          component={FormikTextField}
          label={translate("vat")}
        />
        <Field
          name="street"
          type="text"
          component={FormikTextField}
          label={translate("street")}
        />
        <Field
          name="number"
          type="text"
          component={FormikTextField}
          label={translate("number")}
        />
        <Field
          name="zipcode"
          type="text"
          component={FormikTextField}
          label={translate("zipCode")}
        />
        <Field
          name="city"
          type="text"
          component={FormikTextField}
          label={translate("city")}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyFields;
