import { useContext, useState } from "react";
import { CashRegister } from "../api/types";
import { useAxios } from "../utils/axiosConfig";
import { ToastContext } from "./snackbar";
import { useTranslation } from "react-i18next";
import { getServerErrorMessages } from "../utils/getServerErrorsMessages";
import { ProfileContext } from "./profile";

export const useCashRegister = () => {
  const [cashRegisterData, setCashRegisterData] =
    useState<CashRegister.GetCashRegisterRecordsByMonth.Response | null>(null);
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(1);

  const { t: translate } = useTranslation();
  const { toastHandler } = useContext(ToastContext);
  const { axiosHandler } = useAxios();
  const { openBackDrop } = useContext(ProfileContext) || {};

  const addCashRegister = async (
    payload: CashRegister.AddCashRegisterRecord.Request,
    companyId: number,
    month: number,
    year: number
  ) => {
    openBackDrop(true);
    await axiosHandler()
      .post<CashRegister.AddCashRegisterRecord.Response>(
        "/cash-register-records",
        { ...payload }
      )
      .then((response) => {
        if (response.status === 200) {
          toastHandler({
            toastVisible: true,
            toastMessage: translate("cashAdded"),
            toastType: "success",
          });
          getCashRegisterByMonth({
            companyId,
            month,
            year,
            pageNumber: pageIndex,
            pageSize: pageSize,
          });
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };
  const updateCashRegister = async (
    payload: CashRegister.UpdateCashRegisterRecord.Request,
    companyId: number,
    month: number,
    year: number
  ) => {
    openBackDrop(true);
    await axiosHandler()
      .put<CashRegister.UpdateCashRegisterRecord.Response>(
        "/cash-register-records",
        { ...payload }
      )
      .then((response) => {
        if (response.status === 200) {
          toastHandler({
            toastVisible: true,
            toastMessage: translate("cashUpdated"),
            toastType: "success",
          });

          getCashRegisterByMonth({
            companyId,
            month,
            year,
            pageNumber: pageIndex,
            pageSize: pageSize,
          });
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };
  const getCashRegisterByMonth = async (
    payload: CashRegister.GetCashRegisterRecordsByMonth.Request
  ) => {
    openBackDrop(true);
    await axiosHandler()
      .get(
        `/cash-register-records/month?CompanyId=${payload.companyId}&Month=${
          payload.month + 1
        }&Year=${payload.year}&pageNumber=${payload.pageNumber}&pageSize=${
          payload.pageSize
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          setCashRegisterData(response.data);
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  const deleteCashRegister = async ({
    cashID,
    companyId,
    month,
    year,
  }: {
    cashID: number;
    companyId: number;
    month: number;
    year: number;
  }) => {
    openBackDrop(true);
    await axiosHandler()
      .delete(`/cash-register-records/${cashID}`)
      .then((response) => {
        if (response.status === 200) {
          getCashRegisterByMonth({
            companyId: companyId,
            month: month,
            year: year,
            pageNumber: pageIndex,
            pageSize: pageSize,
          });
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      })
      .finally(() => openBackDrop(false));
  };

  return {
    addCashRegister,
    cashRegisterData,
    getCashRegisterByMonth,
    deleteCashRegister,
    updateCashRegister,
    setPageSize,
    pageSize,
    setPageIndex,
    pageIndex,
  };
};
