import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import CompanyFields from "../../components/CompanyFields/CompanyFields";
import { initialValues, validationSchema } from "./CompanyDetails.config";
import styles from "./CompanyDetails.module.scss";
import { useCompany } from "../../providers/company";
import { ProfileContext } from "../../providers/profile";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ACCOUNTANT_ADMIN, USER_INDEPENDENT } from "../../api/constants";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AddBusiness } from "@mui/icons-material";

export default function CompanyDetails() {
  const { addCompanyDetails } = useCompany();
  const { companiesDetailList, hasRole } = useContext(ProfileContext);
  const { t: translate } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [addLoading, setAddLoading] = useState(false);

  return (
    <div className={styles.companyDetails}>
      <div className={styles.heading}>
        <h2>{translate("companyDetails")} </h2>
        {hasRole([ACCOUNTANT_ADMIN, USER_INDEPENDENT]) && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size={"medium"}
            onClick={() => setOpen(true)}
          >
            <AddBusiness sx={{ mr: 1 }} />
            {translate("addCompany")}
          </Button>
        )}
      </div>
      <Paper className={styles.tableContainer}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translate("name")}</TableCell>
                <TableCell>{translate("vat")}</TableCell>
                <TableCell>{translate("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companiesDetailList.map((company) => (
                <TableRow key={company.companyId!}>
                  <TableCell>{company.companyName}</TableCell>
                  <TableCell>{company.companyVat}</TableCell>
                  <TableCell>
                    {company.companyCreator ? (
                      <Link to={`/company/${company.companyId}`}>
                        {translate("manageCompany")}
                      </Link>
                    ) : (
                      translate("manageNoActions")
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {hasRole([ACCOUNTANT_ADMIN, USER_INDEPENDENT]) && (
        <>
          <br />
          <br />
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
          >
            <DialogTitle id="simple-dialog-title">
              {translate("addCompany")}{" "}
            </DialogTitle>

            <div style={{ padding: "15px" }}>
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                  setAddLoading(true);
                  addCompanyDetails(values)
                    .then(() => {
                      resetForm({});
                    })
                    .finally(() => {
                      setAddLoading(false);
                    });
                }}
              >
                <Form>
                  <CompanyFields />
                  <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" variant="contained" color="primary">
                      {addLoading && (
                        <CircularProgress
                          size={15}
                          color="primary"
                          className={styles.loadingSpinner}
                        />
                      )}
                      {translate("save")}
                    </Button>
                  </Box>
                </Form>
              </Formik>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
}
