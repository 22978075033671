import React from "react";
import NumberFormat from "react-number-format";

interface NumberFormatCustomProps {
  onChange: (event: {
    target: { name: string; value: number | undefined };
  }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props: NumberFormatCustomProps,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      onFocus={(event: any) => event?.target?.select()}
      isNumericString
      fixedDecimalScale={true}
      decimalScale={2}
      allowNegative={true}
      thousandSeparator={"."}
      decimalSeparator={","}
    />
  );
});

export { NumberFormatCustom };
