import React, { useContext } from "react";
import { Button } from "@mui/material";
import { ProfileContext } from "../../providers/profile";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export default function DashboardPage() {
  const navigate = useNavigate();
  const { companyDetailsExist } = useContext(ProfileContext) || {};
  const { t: translate } = useTranslation();

  return (
    <>
      <h1>{translate("dashboard")}</h1>

      <div className="mb-5">
        {!companyDetailsExist && (
          <>
            <h3>{translate("openingStatement")}</h3>
            <Button
              color="primary"
              className="mt-5"
              onClick={() => navigate("/company-details")}
            >
              {translate("addCompany")}
            </Button>
          </>
        )}
      </div>
    </>
  );
}
