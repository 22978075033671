import { useContext, useState } from "react";
import { Companies } from "../api/types";
import { CompanyAddFormValues } from "../pages/companyDetails/CompanyDetails.config";
import { useAxios } from "../utils/axiosConfig";
import { ProfileContext } from "./profile";
import { ToastContext } from "./snackbar";
import { useTranslation } from "react-i18next";
import { getServerErrorMessages } from "../utils/getServerErrorsMessages";

export const useCompany = () => {
  const { profile } = useContext(ProfileContext);
  const { t: translate } = useTranslation();
  const { toastHandler } = useContext(ToastContext);
  const { axiosHandler } = useAxios();
  const { email } = profile;
  const [company, setCompany] =
    useState<Companies.GetCompany.Response | null>(null);

  const addCompanyDetails = async (data: CompanyAddFormValues) => {
    const payload: Companies.AddCompany.Request = {
      ...data,
      email,
      zipcode: Number(data.zipcode),
    };

    await axiosHandler()
      .post<Companies.AddCompany.Response>("company", payload)
      .then((response) => {
        if (response.status === 200) {
          toastHandler({
            toastVisible: true,
            toastMessage: translate("companyAdded"),
            toastType: "success",
          });
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
        return Promise.reject();
      });
  };

  const addUserToCompany = async (
    payload: Companies.AddUserToCompany.Request
  ) => {
    await axiosHandler()
      .post("/company/access", { ...payload })
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  const deleteUserFromCompany = async (
    payload: Companies.DeleteUserFromCompany.Request
  ) => {
    await axiosHandler()
      .delete(
        `/company/access?companyId=${payload.companyId}&userId=${payload.userId}`
      )
      .then((response) => {
        if (response.status === 200) {
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
      });
  };

  const getCompany = async (companyId: number | string) => {
    await axiosHandler()
      .get<Companies.GetCompany.Response>(`/company/${companyId}`)
      .then((response) => {
        if (response.status === 200) {
          setCompany(response.data);
          return Promise.resolve(response.data);
        }
      })
      .catch((error) => {
        for (let e of getServerErrorMessages(error)) {
          toastHandler(e);
        }
        return Promise.reject();
      });
  };

  return {
    addCompanyDetails,
    addUserToCompany,
    deleteUserFromCompany,
    getCompany,
    company,
  };
};
