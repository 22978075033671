import React from 'react';
import { ConfigurationContext } from './ConfigurationContext';
import { Configuration } from './Configuration';

type Props = {
    config: Configuration;
};

export const ConfigurationProvider: React.FC<Props> = ({ children, config }) => {
    return (
        <ConfigurationContext.Provider value={ config }>
            {children}
        </ConfigurationContext.Provider>
    );
};