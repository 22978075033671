import React, { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import "date-fns";
import { dateFormat, yearDateFormat } from "../../utils/dateFormat";
import { useDailyRevenue } from "../../providers/dailyRevenue";
import DisplayRevenueData from "../../components/DisplayRevenueData/DisplayRevenueData";
import { ProfileContext } from "../../providers/profile";
import { dataCalculation } from "../../utils/dataCalculation";
import { useTranslation } from "react-i18next";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import ExportOptionsButton from "./ExportOptionsButton";
import { SimCardDownload } from "@mui/icons-material";

type Props = {
  initialDate: Date;
};

const DailyRevenueExport: React.FC<Props> = ({
  children,
  initialDate = new Date(),
}) => {
  const [startDate, setStartDate] = useState<Date | null>(initialDate);
  const [endDate, setEndDate] = useState<Date | null>(initialDate);
  const {
    fetchCustomRangeDailyRevenue,
    generateVATRateReport,
    generateVATDetailReport,
    customRangeRevenueData,
  } = useDailyRevenue();
  const { selectedCompanyID, paymentMethods } = useContext(ProfileContext);
  const { t: translate } = useTranslation();

  const handleDateChange = (date: Date | null) => {
    setStartDate(date);
  };
  const endDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  useEffect(() => {
    startDate &&
      endDate &&
      selectedCompanyID &&
      fetchCustomRangeDailyRevenue({
        companyId: selectedCompanyID,
        begin: yearDateFormat(startDate),
        end: yearDateFormat(endDate),
        showHistory: true,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, selectedCompanyID, startDate]);

  const desktopDisplay = dataCalculation({
    dataDisplay: customRangeRevenueData,
    paymentMethods,
    customRange: {
      begin: startDate || new Date(),
      end: endDate || new Date(),
    },
  });

  const generateCSVFileVatRate = () => {
    startDate &&
      endDate &&
      selectedCompanyID &&
      generateVATRateReport({
        companyId: selectedCompanyID,
        begin: yearDateFormat(startDate),
        end: yearDateFormat(endDate),
        showHistory: false,
        reportName: `kmodo_dagontvangsten_detail_report_${dateFormat(
          startDate
        )}_to_${dateFormat(endDate)}`,
      });
  };

  const generateCSVFileVatDetail = () => {
    startDate &&
      endDate &&
      selectedCompanyID &&
      generateVATDetailReport({
        companyId: selectedCompanyID,
        begin: yearDateFormat(startDate),
        end: yearDateFormat(endDate),
        showHistory: false,
        reportName: `kmodo_dagontvangsten_report_${dateFormat(
          startDate
        )}_to_${dateFormat(endDate)}`,
      });
  };

  return (
    <>
      <Grid container spacing={3} mb={5} sx={{ alignItems: "center" }}>
        <Grid item xs={12} sm={6} md={3}>
          <DesktopDatePicker<Date>
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <TextField {...params} fullWidth={true} />}
            label={translate("startDate")}
            value={startDate}
            disableFuture
            onChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DesktopDatePicker<Date>
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <TextField {...params} fullWidth={true} />}
            label={translate("endDate")}
            value={endDate}
            disableFuture
            onChange={endDateChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <ExportOptionsButton
            actions={[
              {
                id: "exportDailyRevenue",
                icon: <SimCardDownload />,
                action: generateCSVFileVatDetail,
              },
              {
                id: "exportDailyRevenueDetail",
                icon: <SimCardDownload />,
                action: generateCSVFileVatRate,
              },
            ]}
          />
        </Grid>
      </Grid>

      <div>
        <DisplayRevenueData
          dataDisplay={desktopDisplay}
          showTableActions={false}
        />
      </div>
    </>
  );
};

export default DailyRevenueExport;
