import DatePicker from "@mui/lab/DatePicker";
import { TextField } from "@mui/material";
import { isValid } from "date-fns";
import { default as React, useState } from "react";

interface RestrictedDatePickerInputProps {
  onChange: (date: Date) => void;
  value: Date;
}

const MIN_DATE = new Date(2000, 0, 1);

const RestrictedDatePicker = ({
  onChange,
  value,
}: RestrictedDatePickerInputProps) => {
  const [error, setError] = useState("");

  const max_date = new Date();

  return (
    <DatePicker
      clearable
      disableFuture
      minDate={MIN_DATE}
      inputFormat="dd/MM/yyyy"
      onChange={(date: Date | null) => {
        if (date && isValid(date) && MIN_DATE <= date && date <= max_date) {
          setError("");
          onChange(date);
        } else {
          setError(
            `Date must be between ${MIN_DATE.toLocaleDateString()} and ${max_date.toLocaleDateString()}.`
          );
        }
      }}
      value={value}
      renderInput={(params) => (
        <TextField {...params} error={!!error} helperText={error} />
      )}
    />
  );
};
export default RestrictedDatePicker;
