import React from "react";
import Profile from "../../components/Profile";

export default function DetailPage() {
  return (
    <>
      <h1>Detail</h1>
      <Profile></Profile>
    </>
  );
}
