import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import { loadConfig } from "./config";
import { ConfigurationProvider } from "./config/ConfigurationProvider";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import beLocale from "date-fns/locale/nl-BE";

// import makeStyles from '@mui/styles/makeStyles';

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#074A77",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#F7931E",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
});
// const useStyles = makeStyles((/*theme*/) => {
//   root: {
//     // some CSS that access to theme
//   }
// });

async function init() {
  const config = await loadConfig();

  ReactDOM.render(
    <React.StrictMode>
      <ConfigurationProvider config={config}>
        <Auth0Provider
          domain={config.domain}
          clientId={config.clientId}
          redirectUri={window.location.origin}
          audience={config.apiAudience}
          scope={config.scope}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={beLocale}>
            <BrowserRouter>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <App />
                </ThemeProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </Auth0Provider>
      </ConfigurationProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
