import * as Yup from "yup";
import i18n from "../../locale/i18n";

export type CashRegisterFormValues = {
  day: string;
  relation: string;
  description: string;
  amount: number;
};

export const validationSchema = Yup.object({
  items: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().optional(),
      day: Yup.string().required(i18n.t("dateRequired")),
      relation: Yup.string().optional().nullable(),
      description: Yup.string().required(i18n.t("descriptionRequired")),
      amount: Yup.number()
        .notOneOf([0], i18n.t("amountRequired"))
        .required(i18n.t("amountRequired")),
    })
  ),
  openingBalance: Yup.number().required(),
  closingBalance: Yup.number().required(),
  pageIndex: Yup.number().required(),
  totalPages: Yup.number().required(),
  totalCount: Yup.number().required(),
  pageSize: Yup.number().required(),
  hasPreviousPage: Yup.boolean().required(),
  hasNextPage: Yup.boolean().required(),
});

const toValuesNumber = (value?: number | null) =>
  typeof value === "number" ? value : 0;

export const initialValues = (cashRegisterValues: any | null) => {
  if (cashRegisterValues && cashRegisterValues.items.length > 0) {
    return cashRegisterValues;
  } else {
    return {
      items: [
        {
          id: undefined,
          day: new Date(),
          amount: toValuesNumber(0),
          relation: undefined,
          description: undefined,
        },
      ],
      openingBalance: 0,
      closingBalance: 0,
      pageIndex: 1,
      totalPages: 1,
      totalCount: 1,
      pageSize: 1,
      hasPreviousPage: false,
      hasNextPage: false,
    };
  }
};
