import React from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import styles from "./MobileDisplayRevenueData.module.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDayRevenueSummary } from "../../utils/dataCalculation";
import { currencyFormat } from "../../utils/currencyFormat";
import { useTranslation } from "react-i18next";

const MobileDisplayRevenueData = ({
  mobileDisplay,
}: {
  mobileDisplay: IDayRevenueSummary[];
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.mobileDisplayWrapper}>
      {mobileDisplay.map((item, index: number) => (
        <Accordion className={styles.elementWrapper} key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={styles.element}>
              <div>
                <Typography variant="button" gutterBottom>
                  {item.date.toLocaleDateString()}
                </Typography>
              </div>
              <div>
                <Typography variant="button" gutterBottom>
                  {currencyFormat(item.daytotal)}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} className={styles.elementWrapper}>
                <div className={styles.element}>
                  <div>
                    <Typography variant="button" gutterBottom>
                      0%
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="button" gutterBottom>
                      {currencyFormat(item.zeroPercent)}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className={styles.elementWrapper}>
                <div className={styles.element}>
                  <div>
                    <Typography variant="button" gutterBottom>
                      6%
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="button" gutterBottom>
                      {currencyFormat(item.sixPercent)}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className={styles.elementWrapper}>
                <div className={styles.element}>
                  <div>
                    <Typography variant="button" gutterBottom>
                      12%
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="button" gutterBottom>
                      {currencyFormat(item.twelvePercent)}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className={styles.elementWrapper}>
                <div className={styles.element}>
                  <div>
                    <Typography variant="button" gutterBottom>
                      21%
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="button" gutterBottom>
                      {currencyFormat(item.twentyPercent)}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className={styles.elementWrapper}>
                <div className={styles.element}>
                  <div>
                    <Typography variant="button" gutterBottom>
                      {t("dayTotal")}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="button" gutterBottom>
                      {currencyFormat(item.daytotal)}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default MobileDisplayRevenueData;
